// import React, { useContext, useEffect, useRef, useState } from "react";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import { setBaseUrl } from "../config";
// import { NavbarContext } from "../Context/NavbarContext";
// import { PermissionContext } from "../Context/PermissionsContext";
// import OuterLoader from "../pages/OuterLoder";
// import backgVideo from "../assets/videos/Access-banner.mp4";
// import DurbinLogo from "../assets/images/DurbinLogo.jpg";
// import useLocalstorage from "../utils/useLocalstorage";
// import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
// import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// const Login = () => {
//   const [navState, setNavState] = useContext(NavbarContext);
//   const [perms, setPerms] = useContext(PermissionContext);
//   const { setValues, getValues } = useLocalstorage();
//   const navigate = useNavigate();
//   const inputRef = useRef(null);

//   const [data, setData] = useState({ email: "", password: "" });
//   const [showPassword, setShowPassword] = useState(false);
//   const [visible, setVisible] = useState(true);
//   const [loader, setLoader] = useState(false);

//   useEffect(() => {
//     setNavState(1);
//     checkIfLoggedIn();
//     handleAutofillCheck();
//   }, []);

//   const handleAutofillCheck = () => {
//     setTimeout(() => {
//       const email = document.getElementById("email")?.value;
//       const password = document.getElementById("password")?.value;
//       if (email || password) {
//         setData({ email, password });
//       }
//     }, 100); // Slight delay for autofill detection
//   };

//   const checkIfLoggedIn = () => {
//     const isLoggedIn = sessionStorage.getItem("token");
//     if (isLoggedIn) {
//       navigate("/assets");
//     }
//   };

// const setPermissions = async (token_id) => {
//   try {
//     const { data } = await axios.get(`${setBaseUrl}/company/view-permissions`, {
//       headers: {
//         "Content-Type": "application/json",
//         "x-access-tokens": token_id,
//       },
//     });

//     if (data.permissions) {
//       setValues(data.permissions);
//       setPerms(data.permissions);
//     }

//     return data.message !== "valid token is missing";
//   } catch (error) {
//     console.error(error);
//     return false;
//   }
// };

// const LoginFunction = async () => {
//   try {
//     setLoader(true);
//     const res = await axios.post(`${setBaseUrl}/company/login`, {
//       email: data.email,
//       password: data.password,
//     });

//     const getPermsSuccess = await setPermissions(res.data.token);
//     if (!getPermsSuccess) return;

//     sessionStorage.setItem("token", res.data.token);
//     sessionStorage.setItem("user", data.email);
//     sessionStorage.setItem("asset_tracker_logged_in", "true");

//     const permissions = getValues();
//     setLoader(false);

//     navigateToPage(permissions);
//   } catch (error) {
//     setLoader(false);
//     toast.error("Please Enter Valid Email and Password!");
//   }
// };

// const navigateToPage = (permissions) => {
//   try {
//     if (permissions.includes("ADMIN.ALL")) {
//       toast.success("User Successfully Logged In");
//       navigate("/assets");
//     } else if (
//       ["ASSETS.ALL", "ASSETS.VIEW", "ASSETS.CRU"].some((perm) => permissions.includes(perm))
//     ) {
//       toast.success("User Successfully Logged In");
//       navigate("/assets");
//     } else {
//       navigate("/login");
//     }
//   } catch (err) {
//     console.error("Error navigating to page:", err);
//   }
// };

// const toggleShowPassword = () => {
//   setShowPassword(!showPassword);
//   const passInput = document.getElementById("password");
//   passInput.type = passInput.type === "password" ? "text" : "password";
// };

// if (loader) return <OuterLoader />;

//   return (
//     <div className="flex justify-center items-center h-screen text-xs">
//       <div className="relative w-full h-full">
//         <video className="w-full h-full" src={backgVideo} autoPlay loop muted />
//         <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center bg-black bg-opacity-20">
//           <form className="bg-white text-black p-8 w-[300px] rounded-lg shadow-md">
//             <img
//               src={DurbinLogo}
//               alt="Durbin Logo"
//               className="w-24 h-24 mx-auto rounded-full"
//             />
//             <h1 className="text-center font-extrabold text-gray-600">Asset Tracker</h1>
//             <br />
//             <div className="text-sm font-bold text-blue-500 mb-4">Company Login</div>

//             <div className="mb-4 flex border bg-gray-100 rounded-lg">
//               <input
//                 ref={inputRef}
//                 type="email"
//                 id="email"
//                 placeholder="Email"
//                 onChange={(e) => setData({ ...data, email: e.target.value })}
//                 className="w-full px-4 py-2 text-sm border-none rounded-lg focus:outline-none"
//               />
//             </div>

//             <div className="mb-4 flex border bg-gray-100 rounded-lg">
//               <input
//                 ref={inputRef}
//                 type={showPassword ? "text" : "password"}
//                 id="password"
//                 placeholder="Password"
//                 onChange={(e) => setData({ ...data, password: e.target.value })}
//                 className="w-full px-4 py-2 text-sm border-none rounded-l-lg focus:outline-none"
//               />
//               <div
//                 className="bg-gray-200 text-gray-700 rounded-r-lg flex items-center justify-center cursor-pointer w-10"
//                 onClick={toggleShowPassword}
//               >
//                 {showPassword ? <VisibilityOffOutlinedIcon /> : <RemoveRedEyeOutlinedIcon />}
//               </div>
//             </div>

//             <button
//               type="button"
//               className={`w-full py-3 rounded-lg font-bold text-white ${
//                 data.email && data.password
//                   ? "bg-blue-500 hover:bg-blue-700"
//                   : "bg-gray-400 cursor-not-allowed"
//               }`}
//               onClick={data.email && data.password ? LoginFunction : null}
//               disabled={!data.email || !data.password}
//             >
//               Login
//             </button>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;

import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { setBaseUrl } from "../config";
import { NavbarContext } from "../Context/NavbarContext";
import { PermissionContext } from "../Context/PermissionsContext";
import OuterLoader from "../pages/OuterLoder";
import backgVideo from "../assets/videos/Access-banner.mp4";
import DurbinLogo from "../assets/images/DurbinLogo.jpg";
import useLocalstorage from "../utils/useLocalstorage";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [navState, setNavState] = useContext(NavbarContext);
  const [perms, setPerms] = useContext(PermissionContext);
  const { setValues, getValues } = useLocalstorage();
  const navigate = useNavigate();
  const emailInputRef = useRef(null); // Ref for email input

  const [data, setData] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setNavState(1);
    checkIfLoggedIn();
    focusEmailInput(); // Focus the email input field on load
  }, []);

  const focusEmailInput = () => {
    if (emailInputRef.current) {
      emailInputRef.current.focus(); // Focus the email input field
    }
  };

  const checkIfLoggedIn = () => {
    const isLoggedIn = sessionStorage.getItem("token");
    if (isLoggedIn) {
      navigate("/assets");
    }
  };

  // Rest of the component logic remains unchanged...
  const setPermissions = async (token_id) => {
    try {
      const { data } = await axios.get(
        `${setBaseUrl}/company/view-permissions`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": token_id,
          },
        }
      );

      if (data.permissions) {
        setValues(data.permissions);
        setPerms(data.permissions);
      }

      return data.message !== "valid token is missing";
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const LoginFunction = async () => {
    try {
      setLoader(true);
      const res = await axios.post(`${setBaseUrl}/company/login`, {
        email: data.email,
        password: data.password,
      });

      const getPermsSuccess = await setPermissions(res.data.token);
      if (!getPermsSuccess) return;

      sessionStorage.setItem("token", res.data.token);
      sessionStorage.setItem("user", data.email);
      sessionStorage.setItem("asset_tracker_logged_in", "true");
 

      const permissions = getValues();
      setLoader(false);

      navigateToPage(permissions);
    } catch (error) {
      setLoader(false);
      toast.error("Please Enter Valid Email and Password!");
    }
  };

  const navigateToPage = (permissions) => {
    try {
      if (permissions.includes("ADMIN.ALL")) {
        toast.success("User Successfully Logged In");
        navigate("/assets");
      } else if (
        ["ASSETS.ALL", "ASSETS.VIEW", "ASSETS.CRU"].some((perm) =>
          permissions.includes(perm)
        )
      ) {
        toast.success("User Successfully Logged In");
        navigate("/assets");
      } else {
        navigate("/login");
      }
    } catch (err) {
      console.error("Error navigating to page:", err);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
    const passInput = document.getElementById("password");
    passInput.type = passInput.type === "password" ? "text" : "password";
  };

  if (loader) return <OuterLoader />;

  return (
    <div className="flex justify-center items-center h-screen text-xs">
      <div className="relative w-full h-full">
        <video className="w-full h-full" src={backgVideo} autoPlay loop muted />
        <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center bg-black bg-opacity-20">
          {/* <form className="bg-white text-black p-8 w-[300px] rounded-lg shadow-md"  onSubmit={data.email && data.password ? LoginFunction : null}>
            <img
              src={DurbinLogo}
              alt="Durbin Logo"
              className="w-24 h-24 mx-auto rounded-full"
            />
            <h1 className="text-center font-extrabold text-gray-600">Asset Tracker</h1>
            <br />
            <div className="text-sm font-bold text-blue-500 mb-4">Company Login</div>

            <div className="mb-4 flex border bg-gray-100 rounded-lg">
              <input
                ref={emailInputRef} // Attach the ref to the email input
                type="email"
                id="email"
                placeholder="Email"
                onChange={(e) => setData({ ...data, email: e.target.value })}
                className="w-full px-4 py-2 text-sm border-none rounded-lg focus:outline-none"
              />
            </div>

            <div className="mb-4 flex border bg-gray-100 rounded-lg">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="Password"
                onChange={(e) => setData({ ...data, password: e.target.value })}
                className="w-full px-4 py-2 text-sm border-none rounded-l-lg focus:outline-none"
              />
              <div
                className="bg-gray-200 text-gray-700 rounded-r-lg flex items-center justify-center cursor-pointer w-10"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <VisibilityOffOutlinedIcon /> : <RemoveRedEyeOutlinedIcon />}
              </div>
            </div>

            <button
              type="button"
              className={`w-full py-3 rounded-lg font-bold text-white ${
                data.email && data.password
                  ? "bg-blue-500 hover:bg-blue-700"
                  : "bg-gray-400 cursor-not-allowed"
              }`}
              onMouseEnter={data.email && data.password ? LoginFunction : null}
              disabled={!data.email || !data.password}
            >
              Login
            </button>
          </form> */}
          <form
            className="bg-white text-black p-8 w-[300px] rounded-lg shadow-md"
            onSubmit={(e) => {
              e.preventDefault(); // Prevent the default form submission
              if (data.email && data.password) {
                LoginFunction(); // Call the login function
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && data.email && data.password) {
                e.preventDefault(); // Prevent default action
                LoginFunction(); // Call the login function
              }
            }}
          >
            <img
              src={DurbinLogo}
              alt="Durbin Logo"
              className="w-24 h-24 mx-auto rounded-full"
            />
            <h1 className="text-center font-extrabold text-gray-600">
              Asset Tracker
            </h1>
            <br />
            <div className="text-sm font-bold text-blue-500 mb-4">
              Company Login
            </div>

            <div className="mb-4 flex border bg-gray-100 rounded-lg">
              <input
                ref={emailInputRef}
                type="email"
                id="email"
                placeholder="Email"
                onChange={(e) => setData({ ...data, email: e.target.value })}
                className="w-full px-4 py-2 text-sm border-none rounded-lg focus:outline-none"
              />
            </div>

            <div className="mb-4 flex border bg-gray-100 rounded-lg">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="Password"
                onChange={(e) => setData({ ...data, password: e.target.value })}
                className="w-full px-4 py-2 text-sm border-none rounded-l-lg focus:outline-none"
              />
              <div
                className="bg-gray-200 text-gray-700 rounded-r-lg flex items-center justify-center cursor-pointer w-10"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <VisibilityOffOutlinedIcon />
                ) : (
                  <RemoveRedEyeOutlinedIcon />
                )}
              </div>
            </div>

            {/* <button
              type="button"
              className={`w-full py-3 rounded-lg font-bold text-white ${
                data.email && data.password
                  ? "bg-blue-500 hover:bg-blue-700"
                  : "bg-gray-400 cursor-not-allowed"
              }`}
              onClick={data.email && data.password ? LoginFunction : null}
              disabled={!data.email || !data.password}
            >
              Login
            </button> */}
            <button
              type="button"
              className={`relative w-full py-3 rounded-lg font-bold text-white flex items-center justify-center transition-all duration-300 ${
                data.email && data.password
                  ? "bg-blue-2 hover:bg-blue-1 focus:ring-4 focus:ring-blue-300"
                  : "bg-light-2 cursor-not-allowed"
              }`}
              onClick={data.email && data.password ? LoginFunction : null}
              disabled={!data.email || !data.password}
              aria-disabled={!data.email || !data.password}
              title={
                !data.email || !data.password
                  ? "Please fill out all fields"
                  : ""
              }
            >
              {loader ? (
                <svg
                  className="animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8z"
                  ></path>
                </svg>
              ) : (
                "Login"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
