import axios from "axios";
import React, { useEffect, useState } from "react";
import { setBaseUrl } from "../../../config";
import { useNavigate } from "react-router-dom";
import NewAssetBtn from "../../Buttons/NewAssetBtn";
import MISReportBtn from "../../Buttons/MISReportBtn";
import ExcelReportBtn from "../../Buttons/ExcelReportBtn";
import MaintenanceSearch from "../../Search/MaintenanceSearch";

function Search({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  getAllMaintenance,
  setItems,
  searchType,
  setSearchType,
  setSearchData,
}) {
  const navigate = useNavigate();

// set the state of filter
const [filter, setFilter] = useState(null);

// setting state for search suggestions and page count for pagination
const [searchSuggestions, setSearchSuggestions] = useState({});
const [searchTypeArray, setSearchTypeArray] = useState([]);
const [showSearchDiv, setShowSearchDiv] = useState([]);

const getSearchData = async (e) => {
  
  try {
    const { data } = await axios.get(`${setBaseUrl}/maintenance/search`, {
      headers: {
        "Content-Type": "application/json",
        "x-access-tokens": sessionStorage.getItem("token"),
      },
    });
    // console.log(data);
    setSearchSuggestions(data);
    setItems(data.counter.maintenance);
  } catch (error) {
    console.error(error);
  }
};

const handleSearchCategory = (e) => {
  e.preventDefault();
  const filterInside = e.target.value;
  setFilter(filterInside);
  console.log(filterInside);
  switch (filterInside) {
    case "no_filter": 
      setFilter(null);
      break;
    case "scheduled_date":
      setSearchType("scheduled_date");
      setSearchTypeArray([...searchSuggestions.scheduled_date]);
      break;
    case "assetnumber":
      setSearchType("asset-id");
      setSearchTypeArray([...searchSuggestions.asset_no]);
      break;
    case "lease":
      console.log("lease");
      setSearchType("lease-id");
      setSearchTypeArray([...searchSuggestions.lease_no]);
      break;
    default:
      break;
  }
};

function search(query) {
  if (!query) {
    setShowSearchDiv([]);
    return;
  }
  const filteredData = searchTypeArray.filter((item) => {
    return item.no.toLowerCase().includes(query.toLowerCase());
  });
  setShowSearchDiv(filteredData.length > 0 ? filteredData : ["no results"]);
  console.log(filteredData);
}

const handleEndDate = (e) => {
  const endDate = e.target.value;
  if (endDate < startDate) {
    alert("End date should be older than start date");
    return;
  }
  setEndDate(endDate);
};

const handleSearch = (e) => {
  e.preventDefault();
  search(e.target.value);
  
  // console.log(e.target.value);
  // console.log(searchTypeArray);
};

const handleSearchClick = async (id) => {
  console.log(id);
  setSearchData(id);
  // setGetSearchList((prev) => !prev);
  await getAllMaintenance();
};

useEffect(() => {
  getSearchData();
}, [filter]);

  // const navigate = useNavigate();
  // //set the state of filter
  // const [filter, setFilter] = useState(false);
  // //setting state for search suggestions and page count for pagination
  // const [searchSuggetions, setSearchSuggetions] = useState({});
  // const [searchTypeArray, setSearchTypeArray] = useState([]);
  // const [showSearchDiv, setShowSearchDiv] = useState([]);

  // const getSearchData = async () => {
  //   try {
  //     const { data } = await axios.get(`${setBaseUrl}/maintenance/search`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         "x-access-tokens": sessionStorage.getItem("token"),
  //       },
  //     });
  //     console.log(data);
  //     setSearchSuggetions(data);
  //     setItems(data.counter.maintenance);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const handleSearchCategory = (e) => {
  //   setFilter(e.target.value);
  //   console.log(e.target.value)
  //   let filterInside = e.target.value;
  //   switch (filterInside) {
  //     case "no_filter": 
  //       setFilter((prev) => !prev)
  //       break;
  //     case "scheduled_date":
  //       setSearchType("schedule_date");
  //       // setSearchTypeArray([...searchSuggetions?.scheduled_date]);
  //       break;
  //     case "assetnumber":
  //       setSearchType("asset-id");
  //       setSearchTypeArray([...searchSuggetions.asset_no]);
  //       break;
  //     case "lease":
  //       console.log("lease");
  //       setSearchType("lease-id");
  //       setSearchTypeArray([...searchSuggetions.lease_no]);
  //     default:
  //       break;
  //   }
  // };
  // function search(query) {
  //   if (query == "") {
  //     setShowSearchDiv([]);
  //     return;
  //   }
  //   const filteredData = searchTypeArray.filter((item) => {
  //     if (item.no.toLowerCase().includes(query.toLowerCase())) return item;
  //   });
  //   if (filteredData.length > 0) {
  //     setShowSearchDiv(filteredData);
  //   } else {
  //     setShowSearchDiv(["no results"]);
  //   }

  //   console.log(filteredData);
  // }
  // const handleEndDate = (e) => {
  //   let end_date = e.target.value;
  //   if (end_date < startDate) {
  //     alert("End date should be older than start date");
  //     return;
  //   }
  //   setEndDate(end_date);
  // };
  // const handleSearch = (e) => {
  //   search(e.target.value);
  //   console.log(e.target.value)
  //   console.log(searchTypeArray);
  // };

  // const handleSearchClick = async (id) => {
  //   console.log(id);
  //   setSearchData(id);
  //   // setGetSearchList((prev) => !prev);
  //   await getAllMaintenance();
  // };

  // useEffect(() => {
  //   getSearchData();
  // }, [filter]);
  return (
    <>
      {" "}
      {/* upper tab */}
      <div class="flex  items-center justify-center border-b pb-2">
        <p class="inline font-bold text-xl  text-slate-700 dark:text-slate-200">
          Maintenance
        </p>



        <div class=" sm:flex items-center ml-auto mt-0">
        
        

        

        
  
        {searchType !== "schedule_date" && (  
  
            <div className="">

    <MaintenanceSearch handleSearchCategory={handleSearchCategory} handleSearch={handleSearch}/>

              {showSearchDiv.length > 0 && (
                <div className="bg-white p-2 border-2 border-[#b3a7a7] rounded-[9px]">
                  {showSearchDiv.map((item) => (
                    <p
                      onClick={() => handleSearchClick(item?.id)}
                      className="cursor-pointer"
                    >
                      {item?.no !== undefined ? `${item.no}` : `${item.id} not found`}
                    </p>
                  ))}
                </div>
              )}
            </div>
          )}
          {searchType === "schedule_date" && (
            <>
              <input
                type="date"
                id="datesmaintenance"
                name="dates"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                class="rounded-lg bg-slate-100 border-slate-400 text-sm text-slate-700"
              />
              <span className="mx-4"> to </span>
              <input
                type="date"
                id="datesmaintenance"
                name="dates"
                value={endDate}
                onChange={handleEndDate}
                class="rounded-lg bg-slate-100 border-slate-400 text-sm text-slate-700"
              />
              <button
                onSubmit={handleSearchClick}
                className="bg-[#1920d5] p-2 rounded-lg text-white"
              >
                Search
              </button>
            </>
          )}
        
             </div>
             <NewAssetBtn tabName="maintenance"  />
      </div>
      {/* upper tab ends */}
    </>
  );
}

export default Search;
