// export default NewTable;
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import TablePagination from "@mui/material/TablePagination";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { FaWifi, FaTag } from "react-icons/fa";
import NewAssetBtn from "../../Buttons/NewAssetBtn";
import { capitalizeFirstLetter } from "../../Modals/AssetDetails/Tabs/FormatDate";
import { Button, Tooltip } from "@mui/material";
import { IoIosClose } from "react-icons/io";

const NewTable = ({
  handleExport,
  assetDetails,
  setFilteredData,
  filteredData,
  selectedRows,
  setSelectedRows,
}) => {
  const [filter, setFilter] = useState("All Assets"); // Default filter
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState(""); // Search query
  const [filterStatus, setFilterStatus] = useState('select');
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };

  const handleClearFilter = () => {
    setFilter("All Assets"); // Reset filter to default
    setFilteredData(assetDetails); // Show all data
    setSearchQuery(""); // Clear search
  };

  const selected = [
    { title: "All Assets", label: "all" },
    { title: "Active", label: "active" },
    { title: "Free", label: "free" },
    { title: "Expired", label: "expired" },
  ];

  const status = [
    { title: "Select", label: "select" },
    { title: "Tracker Installed", label: "tracker_installed" },
    { title: "Sold Items", label: "is_sold" },
  ];

  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    applyFilters(); // Apply initial filter and search
  }, [assetDetails, filter, searchQuery, filterStatus]);

  const applyFilters = (customStatuses) => {
    let data = [...assetDetails];
  
    // Apply filterStatus logic (Tracker Installed, Sold Items)
    if (filterStatus) {
      if (filterStatus === "Tracker Installed") {
        data = data.filter((item) => item.device_no && item.device_no.trim() !== "");
      } else if (filterStatus === "Sold Items") {
        data = data.filter((item) => item.is_sold === true);
      }
    }
  
    // Apply customStatuses filter if provided (e.g., "Free", "Expired")
    if (customStatuses && customStatuses !== "sold") {
      // Filter for "Free" items with specific lease_status values (inactive, never assigned)
      data = data.filter((item) => customStatuses.includes(item.lease_status));
    } else if (customStatuses === "sold") {
      // Filter for sold items
      data = data.filter((item) => item.is_sold === true);
    } else {
      // Apply the default filter based on the lease status (Active, Free, Expired)
      if (filter !== "All Assets") {
        if (filter === "Active") {
          data = data.filter(
            (item) =>
              item.lease_status === "active" ||
              (item.lease_status &&
                item.lease_status.toLowerCase().includes("expiring"))
          );
        } else if (filter === "Free") {
          data = data.filter(
            (item) =>
              item.lease_status === "inactive" || item.lease_status === "never assigned"
          );
          setSearchParams("free"); // Update URL with filter
        } else if (filter === "Expired") {
          data = data.filter((item) => item.lease_status === "expired");
        }
      }
    }
  
    // Apply search query if provided
    if (searchQuery.trim()) {
      const lowerCaseQuery = searchQuery.toLowerCase();
      data = data.filter((item) =>
        Object.values(item).some(
          (value) => value && value.toString().toLowerCase().includes(lowerCaseQuery)
        )
      );
    }
  
    // Set filtered data and reset pagination
    setFilteredData(data);
    setPage(0); // Reset to the first page after applying filters
  };
  
  // Handle URL-based filtering
  useEffect(() => {
    const assetParam = searchParams.get("asset");

    if (assetParam === "free") {
      setTimeout(() => {
        applyFilters(["inactive", "never assigned"]); // Apply filter for "Free" status
        setFilter("Free"); // Update filter state
      }, 500);
    } else if (assetParam === "sold") {
      setTimeout(() => {
        applyFilters("sold"); // Apply "Sold Items" filter
        setFilter("Sold Items"); // Update filter state
      }, 500);
    }
    // console.log(assetParam)
  }, [searchParams]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleFilterStatusChange = (event) => {
    setFilterStatus(event.target.value);
  };

  const handleSearch = (event) => {
    if (event.key === "Enter") {
      applyFilters();
    }
  };

  const navigate = useNavigate();

  const getLeaseStatus = (value) => {
    if (value === "inactive" || value === "never assigned") {
      return { status: "Free", color: "#6c757d" }; // Gray
    } else if (value === "expired") {
      return { status: "Expired", color: "#dc3545" }; // Dark Red
    } else if (value === "active") {
      return { status: "Active", color: "#28a745" }; // Green
    } else if (value.toLowerCase().includes("expiring")) {
      const daysMatch = value.match(/(\d+)\s+days/);
      if (daysMatch && daysMatch[1]) {
        const daysLeft = daysMatch[1]; // Extracted number of days
        return { status: `Active (${daysLeft} days left)`, color: "#28a745" };
      }
    }
    return { status: "Unknown", color: "#000" }; // Default case
  };
  // Import Tooltip component

  const columns = [
    {
      field: "counter",
      headerName: "#",
      width: 80,
      renderHeader: () => (
        <Tooltip title="Row Number" arrow placement="top">
          <span>#</span>
        </Tooltip>
      ),
      renderCell: (params) => <>{params.row.counter}</>, // Use the counter field
    },
    {
      field: "asset_no",
      headerName: "Asset No",
      width: 180,
      renderHeader: () => (
        <Tooltip title="Unique identifier for the asset" arrow placement="top">
          <span>Asset No</span>
        </Tooltip>
      ),
      renderCell: (params) => {
        const { row } = params;
        let color = "#000"; // Default color
        let icon = null; // Default icon

        if (row.device_no) {
          color = "#28a745"; // Tracker Installed - Green
          icon = <FaWifi style={{ marginLeft: 5, color: "#28a745" }} />;
        }
        if (row.is_sold === true) {
          color = "red"; // Sold - Red
          icon = <FaTag style={{ marginLeft: 5, color: "red" }} />;
        }

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ color, fontWeight: "bold" }}>{row.asset_no}</span>
            {icon}
          </div>
        );
      },
    },

    {
      field: "make",
      headerName: "Make",
      width: 180,
      renderHeader: () => (
        <Tooltip
          title="Brand or manufacturer of the asset"
          arrow
          placement="top"
        >
          <span>Make</span>
        </Tooltip>
      ),
    },

    {
      field: "model",
      headerName: "Model",
      width: 180,
      renderHeader: () => (
        <Tooltip
          title="The specific model name or number of the asset"
          arrow
          placement="top"
        >
          <span>Model</span>
        </Tooltip>
      ),
    },

    {
      field: "yom",
      headerName: "YOM",
      width: 180,
      renderHeader: () => (
        <Tooltip title="Year of manufacture" arrow placement="top">
          <span>YOM</span>
        </Tooltip>
      ),
    },

    {
      field: "category",
      headerName: "Category",
      width: 180,
      renderHeader: () => (
        <Tooltip title="Category of the asset" arrow placement="top">
          <span>Category</span>
        </Tooltip>
      ),
      renderCell: (params) => {
        const { value } = params;
        const categoryValue =
          value && value.toLowerCase() !== "nill" ? value : "N/A";
        return <span>{categoryValue}</span>;
      },
    },

    {
      field: "serial_no",
      headerName: "Serial No",
      width: 180,
      renderHeader: () => (
        <Tooltip title="Serial number of the asset" arrow placement="top">
          <span>Serial No</span>
        </Tooltip>
      ),
    },

    {
      field: "lease_status",
      headerName: "Lease Status",
      width: 150,
      renderHeader: () => (
        <Tooltip
          title="Current lease status of the asset"
          arrow
          placement="top"
        >
          <span>Lease Status</span>
        </Tooltip>
      ),
      renderCell: (params) => {
        const { value } = params;
        const { status, color } = getLeaseStatus(value);

        return (
          <button
            style={{
              color: color,
              fontWeight: "bold",
              padding: "4px 8px",
            }}
            className={` text-xs`}
          >
            {capitalizeFirstLetter(status)}
          </button>
        );
      },
    },

    // navigate(`/assets/assetDetails/${params.row.id}
  ];

  return (
    <div className=" bg-white">
      <Paper
        style={{
          // padding: 10,
          height: "70vh",
          width: "100%",
          border: "hidden",
        }}
        elevation={0}
      >
        <div className="flex max-md:flex-col justify-between items-center">
          <div className="flex max-md:flex-col  gap-2 justify-center items-center">
            <Tooltip
              title="Search Assets, Category, Model etc."
              placement="top"
              arrow
            >
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={handleSearch}
                placeholder="Search..."
                className="border-light-5 border h-[30px] max-md:w-[300px] w-[200px] p-[5px] rounded-[4px] text-[12px] items-center"
              />
            </Tooltip>
            <FormControl
              fullWidth
              sx={{
                "& .MuiInputLabel-root": { fontSize: "12px" },
                "& .MuiSelect-select": { fontSize: "12px" },
                "& .MuiMenuItem-root": { fontSize: "12px" },
              }}
            >
              <div className="flex gap-2 items-center ">
              <Tooltip placement="top" title="Filter Lease Status" arrow>
                <select
                  className="block w-[150px] p-[6.5px] mt-[0.2px] text-xs px-3 border border-light-5 rounded-[4px] text-dark-6 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={filter}
                  onChange={handleFilterChange}
                >
                  {selected.map((option) => (
                    <option key={option.title} value={option.title}>
                      {option.title}
                    </option>
                  ))}
                </select>
              </Tooltip>
            <Tooltip placement="top" title="Filter Tracker Installed or Sold Assets" arrow>
              <select
                className="block w-[150px] p-[6.5px] mt-[0.2px] text-xs px-3 border border-light-5 rounded-[4px] text-dark-6 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={filterStatus}
                onChange={handleFilterStatusChange}
              >
                {status.map((option) => (
                  <option key={option.title} value={option.title}>
                    {option.title}
                  </option>
                ))}
              </select>
            </Tooltip>

              </div>
            </FormControl>
          </div>

          <div className="flex max-md:flex-col justify-between gap-2 items-center pb-3">
            <button
              className={`shadow-md bg-white border rounded-md p-1.5  ${
                filter === "All Assets" && "hidden"
              } flex items-center text-[12px] text-black`}
            >
              {/* State indicator and label */}
              <span className={`flex items-center mr-2`}>
                <span
                  className={`h-2 w-2 rounded-full mr-1
                  ${
                    filter === "Free"
                      ? "bg-light-2"
                      : filter === "Expired"
                      ? "bg-red-1"
                      : filter === "Sold Items"
                      ? "bg-red-1"
                      : "bg-green-500"
                  }
                   `}
                ></span>{" "}
                {/* Green dot */}
                {filter}
              </span>

              {/* Close icon */}
              <IoIosClose
                className="h-4 w-4 cursor-pointer"
                onClick={() => {
                  setFilter("All Assets");
                  
                }}
              />
            </button>
            
              <button
                className={`shadow-md bg-white border rounded-md p-1.5  ${
                  filterStatus === "Sold Items" || filterStatus === "Tracker Installed" ? '' : "hidden"
                } flex items-center text-[12px] text-black`}
              >
                {/* State indicator and label */}
                <span className={`flex items-center mr-2`}>
                  <span
                    className={`h-2 w-2 rounded-full mr-1
                  ${filterStatus === "Sold Items" ? "bg-red-1" : filterStatus === "Tracker Installed"? "bg-green-500": 'hidden'}
                   `}
                  ></span>{" "}
                  {/* Green dot */}
                  {filterStatus}
                </span>

                {/* Close icon */}
                <IoIosClose
                  className="h-4 w-4 cursor-pointer"
                  onClick={() => {
                    setFilterStatus('select');
                  }}
                />
              </button>
       

            {/* Header Pagination */}

            <Tooltip title="Add pagination" placement="top" arrow>
              <TablePagination
                component="div"
                count={filteredData.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
                // rowsPerPageOptions={[10, 30, 50, filteredData.length]}
                labelRowsPerPage="Rows:"
              />
            </Tooltip>
            <div className="flex items-center gap-3">
              <NewAssetBtn tabName="asset" />
              <Tooltip title="Export Data in Excel format" arrow placement="top">
                <button
                  variant="outlined"
                  sx={{
                    fontSize: 10,
                    color: "#000",
                    width: 104,
                       fontWeight: 'bold',
                       
                  }}
                  onClick={handleExport}
                  className="border text-black buttons bg-white"
                >
                  Export Excel
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
        {filteredData.length > 0 ? (
          <DataGrid
            rows={
              pageSize === filteredData.length
                ? filteredData.map((row, index) => ({
                    ...row,
                    counter: index + 1, // Add row number for all rows
                  }))
                : filteredData
                    .slice(page * pageSize, page * pageSize + pageSize) // Paginated rows
                    .map((row, index) => ({
                      ...row,
                      counter: page * pageSize + index + 1, // Adjust counter for paginated rows
                    }))
            }
            onRowClick={(params) => {
              const rowId = params.row.id; // Use the row ID or another property to navigate
              navigate(`/assets/asset-details/${rowId}`); // Adjust the route as needed
            }}
            columns={columns}
            // checkboxSelection
            paginationMode="server"
            pagination={false} // Disable built-in pagination
            sx={{
              "& .MuiDataGrid-footerContainer": {
                display: "none", // Hides the footer container
              },

              "& .MuiDataGrid-root": { fontSize: "12px", height: 30 },
              "& .MuiDataGrid-columnHeaders": {
                fontSize: "12px",
                backgroundColor: "#000",
                fontWeight: "bold",
                color: "#006DB6",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
                height: 20,
              },
              "& .MuiDataGrid-cell": { fontSize: "12px", cursor: "pointer" },
              "& .MuiDataGrid-virtualScroller": {
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                "-ms-overflow-style": "none",
                "scrollbar-width": "none",
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
            }}
          />
        ) : (
          <>
            <div className="text-xs items-center flex justify-center p-10 border">
              No Assets Found in your search area
            </div>
          </>
        )}
      </Paper>
    </div>
  );
};

export default NewTable;
