
import React, { useState } from "react";
import TooltipFun from "../../TooltipFun";

function NewAssetConfigForm({ assetConfigDetails, setAssetConfigDetails }) {
  return (
    <>
      <div className="my-5">
        <button className="text-xs font-semibold text-dark-6 underline">
          Configuration Details
        </button>
        <div className="border p-10 mt-2 text-xs gap-4 bg-white dark:bg-slate-900 grid grid-cols-1 md:grid-cols-4 ">
          {/* Used or New */}
          {/* <div className="flex-1 w-[200px]">
            <TooltipFun title="Used/New" placement="top-end"></TooltipFun>
            <label className="font-bold">Used/New</label>
            <select
              value={assetConfigDetails?.used_or_new || ''}
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setAssetConfigDetails({
                  ...assetConfigDetails,
                  used_or_new: e.target.value,
                });
              }}
            >
              <option value="">Select an option...</option>
              <option value="used">Used</option>
              <option value="new">New</option>
            </select>
          </div> */}

          <div className="flex-1 w-[200px]">
            <TooltipFun title="Used/New" placement="top-end">
              <label className="font-bold">Used/New</label>
            </TooltipFun>
            <select
              value={assetConfigDetails?.used_or_new || ""}
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setAssetConfigDetails({
                  ...assetConfigDetails,
                  used_or_new: e.target.value,
                });
              }}
            >
             <option value="">Select an option...</option>
              <option value="used">Used</option>
              <option value="new">New</option>
            </select>
          </div>

          {/* ANSI/CE */}
          <div className="flex-1 w-[200px]">
            <TooltipFun title="ANSI/CE" placement="top-end">
              <label className="font-bold">ANSI/CE</label>
            </TooltipFun>
            <select
              value={assetConfigDetails?.ansi_or_new || ""}
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setAssetConfigDetails({
                  ...assetConfigDetails,
                  ansi_or_new: e.target.value,
                });
              }}
            >
              <option value="">Select an option...</option>
              <option value="used">ANSI</option>
              <option value="new">CE</option>
            </select>
          </div>

          {/* Machine Ownership Ship Type */}
          <div className="flex-1 w-[200px]">
            <TooltipFun title="Machine Ownership Ship Type" placement="top-end">
              <label className="font-bold">Machine Ownership Ship Type</label>
            </TooltipFun>
            <select
              value={assetConfigDetails?.machine_ownership_type || ""}
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setAssetConfigDetails({
                  ...assetConfigDetails,
                  machine_ownership_type: e.target.value,
                });
              }}
            >
              <option value="">Select an option...</option>
              <option value="rental">Rental</option>
              <option value="sands">S and S</option>
              <option value="retail">Retail</option>
              <option value="sold">Sold</option>
            </select>
          </div>

          {/* DIESEL/BATTERY */}
          <div className="flex-1 w-[200px]">
            <TooltipFun title="Drive Train" placement="top-end">
              <label className="font-bold">Drive Train</label>
            </TooltipFun>
            <select
              value={assetConfigDetails?.battery_type || ""}
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setAssetConfigDetails({
                  ...assetConfigDetails,
                  battery_type: e.target.value,
                });
              }}
            >
              <option value="">Select an option...</option>
              <option value="Diesel">Diesel</option>
              <option value="battery">Battery</option>
            </select>
          </div>

          {/* Engine Serial No */}
          <div className="flex-1 w-[200px]">
            <TooltipFun title="Engine Serial No" placement="top-end">
              <label className="font-bold">Engine Serial Number</label>
            </TooltipFun>
            <input
              type="text"
              value={assetConfigDetails?.engine_serial_no || ""}
              placeholder="Engine Serial No"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setAssetConfigDetails({
                  ...assetConfigDetails,
                  engine_serial_no: e.target.value,
                });
              }}
            />
          </div>

          {/* 2WD / 4WD */}
          <div className="flex-1 w-[200px]">
            <TooltipFun title="Drive Train" placement="top-end">
              <label className="font-bold">Wheel Drive Type</label>
            </TooltipFun>
            <select
              value={assetConfigDetails?.two_or_four_wd}
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setAssetConfigDetails({
                  ...assetConfigDetails,
                  two_or_four_wd: e.target.value,
                });
              }}
            >
              <option value="">Select an option...</option>
              <option value="2WD">2WD</option>
              <option value="4WD">4WD</option>
            </select>
          </div>

          {/* Accessories */}
          <div className="flex-1 w-[200px]">
            <TooltipFun title="Accessories (if any)" placement="top-end">
              <label className="font-bold">Accessories (if any)</label>
            </TooltipFun>
            <input
              type="text"
              value={assetConfigDetails?.accessories}
              placeholder="Accessories"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setAssetConfigDetails({
                  ...assetConfigDetails,
                  accessories: e.target.value,
                });
              }}
            />
          </div>

          {/* Tires */}
          <div className="flex-1 w-[200px]">
            <TooltipFun title="Tires" placement="top-end">
              <label className="font-bold">Tires</label>
            </TooltipFun>
            <select
              value={assetConfigDetails?.tyres || ""}
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setAssetConfigDetails({
                  ...assetConfigDetails,
                  tyres: e.target.value,
                });
              }}
            >
              <option value="">Select an option...</option>
              <option>Airfilled</option>
              <option>Foam</option>
            </select>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewAssetConfigForm;
