import React, { useContext, useEffect, useState } from "react";
import AssetTable from "../components/tables/AssetTable/AssetTable";
import AssetDetails from "../components/Modals/AssetDetails/AssetDetails";
import AssetHistory from "../components/Modals/AssetHistory";
import AssetTelematics from "../components/Modals/AssetTelematics";
import UpdateAssets from "../components/Modals/UpdateAssets";
import NewAssetTab from "../components/Modals/NewAsset/NewAsset";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";


import { NavbarContext } from "../Context/NavbarContext";
import Layout from "../components/Layout";
import { PermissionContext } from "../Context/PermissionsContext";

function Assets() {
  const [perms, setPerms] = useContext(PermissionContext);
  const navigate = useNavigate();
  const [navState, setNavState] = useContext(NavbarContext);

  // set the state of the current asset tab
  const [showCurrentTab, setShowCurrentTab] = useState(1);



  useEffect(() => {
    if (sessionStorage.getItem("asset_tracker_logged_in") !== "true") {
      navigate("/login");
    }
    setNavState(2);
  }, []);
  useEffect(() => {
    let getPermissionsFromSession = JSON.parse(
      sessionStorage.getItem("permissions")
    );
    setPerms(getPermissionsFromSession);
  }, []);
  return (
    <Layout 
    showCurrentTab={showCurrentTab}
    setShowCurrentTab={setShowCurrentTab}
    >
      <div class="tabList" id="tab-assets">
        <div id="main-assets" class="block ">
          <Routes>
            <Route
              path="/:query?"
              element={
                <AssetTable
                  showCurrentTab={showCurrentTab}
                  setShowCurrentTab={setShowCurrentTab}
                />
              }
            />
            <Route path="/new-asset" element={<NewAssetTab />} />
            <Route path="/asset-details/:id/:tab?" element={<AssetDetails  setShowCurrentTab={setShowCurrentTab}/>} />
            <Route path="/update-asset/:id" element={<UpdateAssets />} />
          </Routes>
         </div>

      
       
      </div>
    </Layout>
  );
}

export default Assets;
