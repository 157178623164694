import React, { useEffect, useRef, useState } from "react";
import ImageUploading from "react-images-uploading";
import BasicDetails from "./BasicDetails";
import ConfigDetails from "./ConfigDetails";
import CommercialDetails from "./CommercialDetails";
import { setBaseUrl } from "../../../config";
import axios from "axios";
import Loader from "../../Loader";
import { useNavigate } from "react-router-dom";
import NewBasicDetails from "./NewBasicDetails";
import NewAssetConfigForm from "./NewAssetConfigForm";
import NewCommercialDetails from "./NewCommercialDetails";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "@mui/material";

function NewAssetTab({ setShowCurrentTab }) {
  // set loader state
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  //setting state for allowing option for commercial details
  const [commToggle, setCommToggle] = useState(false);

  //target the image input when clicked on the button
  //the button will have an handleInputImage function which will invoke the image input field by triggering the ref of that input
  const imageInputRef = useRef(null);
  //set image
  const [uploadedImage, setUploadedImage] = useState(null);
  const [succesfullUpdateDoc, setSuccessfullUpdateDoc]  = useState(false)
  //target the document input element by using useRef hook
  const documentInputRef = React.useRef(null);
  const [uploadedDocument, setUploadedDocument] = useState(null);
  const [showImage, setShowImage] = useState(null);
  const [showDoc, setShowDoc] = useState(null);
  const [assetBasicDetails, setAssetBasicDetails] = useState({
    asset_no: "",
    make: "",
    model: "",
    yom: "",
    category: "",
    capacity: "",
    description: "",
    serial_no: "",
    purchased_from: "",
    rfid: "",
    hieght_machine: "",
    device_no: null,
    site_location: "",
    rating: "",
    //company_id:
    is_sold: "",
    // sold_machine_data: "",
    doc_expiry_date: new Date(),
    doc_types: "doc",
    installation_date: "",
    custom_duty_date_of_clearance: "",
  });

  const [assetConfigDetails, setAssetConfigDetails] = useState({
    used_or_new: "new",
    ansi_or_new: "ansi",
    machine_ownership_type: "rental",
    battery_type: "Diesel",
    engine_serial_no: "",
    two_or_four_wd: "2WD",
    accessories: "",
    tyres: "Airfilled",
    asset_id: "",
  });

  const [commercialDetails, setCommercialDetails] = useState({
    purchase_order_no: "",
    purchase_order_date: "",
    invoice_no: "",
    invoice_date: "",
    payment_terms: "",
    amount_rem_to_oem: "",
    date_of_rem_to_oem: "",
    exchange_rate_rem: "",
    custom_duty_payment: "",
    exworks_price: "",
    cif_charges: "",
    total_cost: "",
    boe_no: "",
    custom_duty_value: "",
    gst_amount: "",
    exrate_boe: "",
    clearing_charges: "",
    cha_charges: "",
    transportation_charges: "",
    port_of_dispatch: "",
    port_of_clearance: "",
    period_of_insurance: "",
    insurance_renewal: "",
    total_landed_cost: "",
    total_landed_cost_with_gst: "",
    asset_id: "",
  });

  const [imageError, setImageError] = useState(null);
  const [docError, setDocError] = useState(null);

  const handleImageChange = () => {
    // Trigger the file input to open when button is clicked
    imageInputRef.current.click();
  };

  const handleImageUploadChange = (e) => {
    const photoFiles = e.target.files;
    // Convert FileList to Array using Array.from
    const photoArray = Array.from(photoFiles);

    // Update state for images
    setUploadedImage(photoFiles);
    setShowImage(photoArray);
  };

  // Handle document change for file input
  const handleDocumentChange = (e) => {
    const docFiles = e.target.files;
    // Convert FileList to Array using Array.from
    const docArray = Array.from(docFiles);

    // Update state for documents
    setUploadedDocument(docFiles);
    setShowDoc(docArray);
  };

  //invoke the document upload input on button click
  const handleDocumentUploadInput = (e) => {
    documentInputRef.current.click();
  };
  //upload the image to the database
  async function handleUploadImage(asset_id) {
    // console.log(asset_id);
    // console.log(uploadedImage);
    if (uploadedImage) {
      const formData = new FormData();
      for (const key in uploadedImage) {
        if (uploadedImage.hasOwnProperty(key))
          formData.append("photo", uploadedImage[key]);
      }
      formData.append("types", "asset");
      formData.append("asset_id", asset_id);
      //   for (var pair of formData.entries()) {
      //     console.log(pair[0]+ ', ' + pair[1]);
      // }
      await axios
        .post(`${setBaseUrl}/asset/upload_photo`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-access-tokens": sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log(response.data);
          toast.error("Photo Uploadation failed!!")
          setSuccessfullUpdateDoc(true)
          return;
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
  // upload the document to the database
  async function handleDocument(asset_id) {
    if (uploadedDocument) {
      const formData = new FormData();
      for (const key in uploadedDocument) {
        if (uploadedDocument.hasOwnProperty(key)) {
          formData.append("attachment", uploadedDocument[key]);
        }
      }
      formData.append("types_section", "asset");
      formData.append("asset_id", asset_id);
      formData.append("doc_types", "document");
      formData.append("doc_expiry_date", new Date());
      formData.append("serial_no", assetBasicDetails?.serial_no);
      //       types_section:asset
      // serial_no:assetBasicDetails.serial_no
      // doc_types:document
      // doc_expiry_date:13/06/2024
      // asset_id:7d9ec0a8-1aab-4ef0-afa6-c1392413f571
      //   for (var pair of formData.entries()) {
      //     console.log(pair[0]+ ', ' + pair[1]);
      // }
      await axios
        .post(`${setBaseUrl}/asset/upload_attachment`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-access-tokens": sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log(response.data);
          if(response.status === 413 ){
            toast.error("Document Upload failed!!")
            setSuccessfullUpdateDoc(true)
            return;
          }
        })
        .catch((error) => {
          console.error(error);

        });
    }
  }

  // const handleSubmit = async () => {
  //   if (assetBasicDetails.category === "") {
  //     alert("Please select a category");
  //     return;
  //   }
  //   try {
  //     setLoader(true);
  //     //first an asset will be created with basic details
  //     const data1 = await axios.post(
  //       `${setBaseUrl}/asset/create`,
  //       assetBasicDetails,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           "x-access-tokens": sessionStorage.getItem("token"),
  //         },
  //       }
  //     );
  //     console.log(data1);
  //     //get the id of newly created asset
  //     let getAssetId = data1.data.asset_id;

  //     await handleUploadImage(getAssetId);
  //     await handleDocument(getAssetId);
  //     let updatedConfigDetails = {
  //       ...assetConfigDetails,
  //       asset_id: getAssetId,
  //     };
  //     console.log(updatedConfigDetails);
  //     //send request to the create config details with the above id
  //     const data2 = await axios.post(
  //       `${setBaseUrl}/asset/create_config`,
  //       updatedConfigDetails,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           "x-access-tokens": sessionStorage.getItem("token"),
  //         },
  //       }
  //     );
  //     console.log(data2);
  //     //send request to the create commercial details with the above id only if commToggle is true
  //     let data3;
  //     if (commToggle) {
  //       let updatedCommercialDetails = {
  //         ...commercialDetails,
  //         asset_id: getAssetId,
  //       };
  //       data3 = await axios.post(
  //         `${setBaseUrl}/asset/create_details`,
  //         updatedCommercialDetails,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             "x-access-tokens": sessionStorage.getItem("token"),
  //           },
  //         }
  //       );
  //     }
  //     console.log(data3);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setLoader(false);
  //     navigate("/assets");
  //   }
  // };

  // const handleSubmit = async () => {
  //   // Validate required fields
  //   const { asset_no, make, model } = assetBasicDetails;

  //   if (!asset_no || !make || !model) {
  //     if (!asset_no) toast.error("Asset number is required");
  //     if (!make) toast.error("Make is required");
  //     if (!model) toast.error("Model Number is required");
  //     return; // Stop execution if validation fails
  //   }

  //   try {
  //     setLoader(true);

  //     // Create the asset with basic details
  //     const data1 = await axios.post(
  //       `${setBaseUrl}/asset/create`,
  //       assetBasicDetails,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           "x-access-tokens": sessionStorage.getItem("token"),
  //         },
  //       }
  //     );
  //     // console.log(data1);

  //     // Get the ID of the newly created asset
  //     let getAssetId = data1.data.asset_id;

  //     // Handle related data creation
  //     await handleUploadImage(getAssetId);
  //     await handleDocument(getAssetId);

  //     // Update configuration details and send the request
  //     let updatedConfigDetails = {
  //       ...assetConfigDetails,
  //       asset_id: getAssetId,
  //     };
  //     console.log(updatedConfigDetails);

  //     const data2 = await axios.post(
  //       `${setBaseUrl}/asset/create_config`,
  //       updatedConfigDetails,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           "x-access-tokens": sessionStorage.getItem("token"),
  //         },
  //       }
  //     );
  //     // console.log(data2);

  //     // Optionally handle commercial details
  //     let data3;
  //     if (commToggle) {
  //       let updatedCommercialDetails = {
  //         ...commercialDetails,
  //         asset_id: getAssetId,
  //       };
  //       data3 = await axios.post(
  //         `${setBaseUrl}/asset/create_details`,
  //         updatedCommercialDetails,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             "x-access-tokens": sessionStorage.getItem("token"),
  //           },
  //         }
  //       );
  //     }
  //     // console.log(data3);

  //     // Show success toast
  //     toast.success("Asset successfully created");
  //     navigate("/assets");
  //   } catch (error) {
  //     console.error(error);

  //     // Show error toast
  //     toast.error("Asset creation failed. Please Enter the required fields properly");
  //   } finally {
  //     setLoader(false);
  //   }
  // };
  const [formErrors, setFormErrors] = useState({
    asset_no: "",
    make: "",
    model: "",
    yom: ""
    // Add other required fields here if necessary
  });

  const [touched, setTouched] = useState({
    asset_no: false,
    make: false,
    model: false,
    serial_no: false,
    yom: false
  });

  const handleBlur = (field) => {
    setTouched({ ...touched, [field]: true });

    // Perform validation on blur
    if (!assetBasicDetails[field]) {
      setFormErrors({ ...formErrors, [field]: true });
    } else {
      setFormErrors({ ...formErrors, [field]: false });
    }
  };

  const [deviceNameError, setDeviceNameError] = useState(null);

  // const handleSubmit = async () => {
  //   // Validate required fields
  //   const { asset_no, make, model, serial_no, yom } = assetBasicDetails;

  //   let errors = {
  //     asset_no: !asset_no,
  //     make: !make,
  //     model: !model,
  //     serial_no: !serial_no,
  //     yom: !yom,
  //     // Add other fields if needed
  //   };

  //   // Check if there are any errors
  //   setFormErrors(errors);

  //   // If there are errors, stop form submission
  //   if (Object.values(errors).includes(true)) {
  //     toast.error("Please fill out all required fields.");
  //     return; // Stop execution if validation fails
  //   }

  //   if(succesfullUpdateDoc) {
  //     return;
  //   }

  //   try {
  //     setLoader(true);

  //     // Create the asset with basic details
  //     const data1 = await axios.post(
  //       `${setBaseUrl}/asset/create`,
  //       assetBasicDetails,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           "x-access-tokens": sessionStorage.getItem("token"),
  //         },
  //       }
  //     );

  //     // Get the ID of the newly created asset
  //     let getAssetId = data1.data.asset_id;

  //     // Handle related data creation
  //     await handleUploadImage(getAssetId);
  //     await handleDocument(getAssetId);

  //     // Update configuration details and send the request
  //     let updatedConfigDetails = {
  //       ...assetConfigDetails,
  //       asset_id: getAssetId,
  //     };

  //     const data2 = await axios.post(
  //       `${setBaseUrl}/asset/create_config`,
  //       updatedConfigDetails,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           "x-access-tokens": sessionStorage.getItem("token"),
  //         },
  //       }
  //     );

  //     // Optionally handle commercial details
  //     let data3;
  //     if (commToggle) {
  //       let updatedCommercialDetails = {
  //         ...commercialDetails,
  //         asset_id: getAssetId,
  //       };
  //       data3 = await axios.post(
  //         `${setBaseUrl}/asset/create_details`,
  //         updatedCommercialDetails,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             "x-access-tokens": sessionStorage.getItem("token"),
  //           },
  //         }
  //       );
  //     }

  //     // Show success toast
  //     toast.success("Asset successfully created");
  //     navigate("/assets");
  //   } catch (error) {
  //     if (error.response.data.error === "Can't find device with this name") {
  //       toast.error(
  //         "Can't find device with this name, please add registered device name!"
  //       );
  //       setDeviceNameError(error);
  //     } else
  //       toast.error(
  //         "Asset creation failed. Please Enter the required fields properly"
  //       );
  //   } finally {
  //     setLoader(false);
  //   }
  // };

  const handleSubmit = async () => {
    // Validate required fields
    const validateFields = (fields) => {
      const errors = {};
      for (const [key, value] of Object.entries(fields)) {
        errors[key] = !value;
      }
      return errors;
    };
  
    const requiredFields = ["asset_no", "make", "model", "serial_no", "yom"];
    const validationErrors = validateFields(
      requiredFields.reduce((acc, field) => {
        acc[field] = assetBasicDetails[field];
        return acc;
      }, {})
    );
  
    setFormErrors(validationErrors);
  
    if (Object.values(validationErrors).some((error) => error)) {
      toast.error("Please fill out all required fields.");
      return; // Stop execution if validation fails
    }
  
    if (succesfullUpdateDoc) {
      toast.info("Document already updated successfully.");
      return;
    }
  
    try {
      setLoader(true);
  
      // Reusable headers
      const headers = {
        "Content-Type": "application/json",
        "x-access-tokens": sessionStorage.getItem("token"),
      };
  
      // Create the asset with basic details
      const { data: assetData } = await axios.post(
        `${setBaseUrl}/asset/create`,
        assetBasicDetails,
        { headers }
      );
      const getAssetId = assetData.asset_id;
  
      // Execute related operations in parallel
      const uploadImage = handleUploadImage(getAssetId);
      const uploadDocument = handleDocument(getAssetId);
  
      const updatedConfigDetails = {
        ...assetConfigDetails,
        asset_id: getAssetId,
      };
      const createConfig = axios.post(
        `${setBaseUrl}/asset/create_config`,
        updatedConfigDetails,
        { headers }
      );
  
      let createCommercial;
      if (commToggle) {
        const updatedCommercialDetails = {
          ...commercialDetails,
          asset_id: getAssetId,
        };
        createCommercial = axios.post(
          `${setBaseUrl}/asset/create_details`,
          updatedCommercialDetails,
          { headers }
        );
      }
  
      // Wait for all operations to complete
      const results = await Promise.allSettled([
        uploadImage,
        uploadDocument,
        createConfig,
        commToggle ? createCommercial : Promise.resolve(),
      ]);
  
      // Check for errors
      const failedResults = results.filter((result) => result.status === "rejected");
      if (failedResults.length > 0) {
        toast.error("Some operations failed. Please check and try again.");
        console.error("Failed operations:", failedResults);
      } else {
        toast.success("Asset successfully created!");
        navigate("/assets");
      }
    } catch (error) {
      if (error.response?.data?.error === "Can't find device with this name") {
        toast.error(
          "Can't find device with this name, please add registered device name!"
        );
        setDeviceNameError(error);
      } else {
        toast.error(
          "Asset creation failed. Please enter the required fields properly."
        );
      }
    } finally {
      setLoader(false);
    }
  };

  
  
  if (loader) {
    return <Loader />;
  } else {
    return (
      <div id="new-asset" className="animfadein">
        <div class="flex  text-dark-6 py-2">
          <p class="inline   font-bold text-dark-6">
            {/* if showCurrentTab equals to 5 it means we have opened new Asset tab or it is Update asset tab */}
            Create New Asset
          </p>
        </div>
        {/* <BasicDetails
          assetBasicDetails={assetBasicDetails}
          setAssetBasicDetails={setAssetBasicDetails}
        /> */}

        <NewBasicDetails
          assetBasicDetails={assetBasicDetails}
          setAssetBasicDetails={setAssetBasicDetails}
          formErrors={formErrors}
          deviceNameError={deviceNameError}
          handleBlur={handleBlur}
          touched={touched}
          setTouched={setTouched}
        />
        {/* <ConfigDetails
          assetConfigDetails={assetConfigDetails}
          setAssetConfigDetails={setAssetConfigDetails}
        /> */}
        <NewAssetConfigForm
          assetConfigDetails={assetConfigDetails}
          setAssetConfigDetails={setAssetConfigDetails}
        />

        {/* <CommercialDetails
          commToggle={commToggle}
          setCommToggle={setCommToggle}
          commercialDetails={commercialDetails}
          setCommercialDetails={setCommercialDetails}
        /> */}
        <NewCommercialDetails
          commToggle={commToggle}
          setCommToggle={setCommToggle}
          commercialDetails={commercialDetails}
          setCommercialDetails={setCommercialDetails}
        />
        {/* Old ui */}

        <div class="flex animSlideup relative mb-24" >
          <div class="basis-full bg-white rounded-[8px] text-xs mx-2 mt-5 border  justify-between">
            <div class="flex p-5 border-b border-gray-200 ">
              <h2 class="font-medium  mr-auto ">Upload Asset Photos (jpeg, jpg, png ) (max 5MB)</h2>
              <button class="bg-blue-3 text-white rounded-[6px] h-6 px-2 text-xs"
              onClick={() => handleImageChange()}
              >
                <input
                  ref={imageInputRef}
                  type="file"
                  accept="image/jpeg, image/png"
                  multiple
                  onChange={handleImageUploadChange}
                  wfd-id="id57"
                  className="hidden"
                />
                Browse Photos
              </button>
            </div>
            {uploadedImage && <div class="p-5 text-sm">
              <div class="flex flex-col text-base gap-2">
                
                {showImage.length > 0 && showImage.map(img => <p className="text-xs">{img.name}</p>)}
              </div>
            </div>}
          </div>
          <div className="basis-full bg-white rounded-[8px] mx-2 mt-5  border justify-between">
            <div class="flex p-5 border-b border-gray-200 ">
              <h2 class="font-medium text-xs mr-auto ">
               Upload Maintenance Documents (PDF, Word, Excel ) (max. 10MB)
              </h2>
              <button
                onClick={handleDocumentUploadInput}
                class="bg-blue-3 text-white rounded-[6px] h-6 px-2 text-xs"
              >
                <input
                  ref={documentInputRef}
                  type="file"
                  multiple
                  accept=".doc,.pdf,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  class="hidden"
                  wfd-id="id57"
                  onChange={handleDocumentChange}
                />
                Browse Documents
              </button>
            </div>
            <div class="p-5 text-xs">
              {uploadedDocument && (
                <div class="flex flex-col ">{showDoc.length>0 && showDoc.map(doc=><p>{doc.name}</p>)}</div>
              )}
            </div>
          </div>
        </div>

        {/* New UI */}
        {/* <div className="flex gap-3 mt-2 pb-16 text-xs justify-between">
       
          <div className="border-2 border-dashed border-gray-300 rounded-lg p-6 mb-2 w-[400px]">
            <div className="flex p-5 border-gray-200">
              <button
                className="flex flex-col items-center justify-center text-gray-500 hover:text-gray-700 w-full"
                onClick={handleImageChange}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-12 h-12 mb-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 16.5V19a2 2 0 002 2h14a2 2 0 002-2v-2.5M16 10l-4-4m0 0L8 10m4-4v12"
                  />
                </svg>
                <input
                  ref={imageInputRef}
                  type="file"
                  accept="image/jpeg, image/png"
                  multiple
                  onChange={handleImageUploadChange}
                  className="hidden"
                />
                Click to upload photos
              </button>
            </div>
            {imageError && <p className="text-xs text-red-500">{imageError}</p>}
            {uploadedImage && (
              <div className="p-5 text-xs">
                <div className="flex flex-col text-base gap-2">
                  {Array.from(uploadedImage).map((img) => (
                    <p key={img.name} className="text-xs">
                      {img.name}
                    </p>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="border-2 border-dashed border-gray-300 rounded-lg p-6 mb-2 w-[400px]">
            <div className="flex p-5 border-gray-200">
              <button
                className="flex flex-col items-center justify-center text-gray-500 hover:text-gray-700 w-full"
                onClick={handleDocumentUploadInput}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-12 h-12 mb-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 16.5V19a2 2 0 002 2h14a2 2 0 002-2v-2.5M16 10l-4-4m0 0L8 10m4-4v12"
                  />
                </svg>
                <input
                  ref={documentInputRef}
                  id="fileUpload"
                  type="file"
                  className="hidden"
                  multiple
                  accept=".pdf, .doc, .docx, .xls, .xlsx"
                  onChange={handleDocumentChange}
                />
                Click to upload Documents (PDF, Word, Excel)
              </button>
            </div>
            {docError && <p className="text-xs text-red-500">{docError}</p>}
            {uploadedDocument && (
              <div className="p-5 text-xs">
                <div className="flex flex-col">
                  {Array.from(uploadedDocument).map((doc) => (
                    <p key={doc.name}>{doc.name}</p>
                  ))}
                </div>
              </div>
            )}
         
          </div>
        </div> */}
          {/* Document Upload Section */}
          {/* <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-lg p-6 mb-2 w-[400px]">
            <label
              htmlFor="fileUpload"
              className="cursor-pointer flex flex-col items-center justify-center text-gray-500 hover:text-gray-700 transition w-full"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-12 h-12 mb-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 16.5V19a2 2 0 002 2h14a2 2 0 002-2v-2.5M16 10l-4-4m0 0L8 10m4-4v12"
                />
              </svg>
              <span className="text-xs font-medium">
                Click to upload Documents (PDF, Word, Excel)
              </span>
              <input
                ref={documentInputRef}
                id="fileUpload"
                type="file"
                className="hidden"
                multiple
                accept=".pdf, .doc, .docx, .xls, .xlsx"
                onChange={handleDocumentChange}
              />
            </label>
       
          </div> */}

        {/* Button */}
        <div className="h-12 bottom-0 border-t items-center flex justify-end  left-10 right-0 fixed z-50  bg-white shadow-2xl ">
          <div className="flex mr-6 justify-between items-center gap-3 text-xs">
            <Button
              onClick={() => navigate("/assets")}
              variant="outlined"
              sx={{
                fontSize: "12px",
                color: "#7C7C7C",
                borderColor: "#7C7C7C",
                textTransform: "none", // To disable uppercase text transformation
              }}
            >
              Back
            </Button>

            <Button
              variant="contained"
              sx={{
                fontSize: "12px",
                color: "white",
                textTransform: "none", // To disable uppercase text transformation
              }}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default NewAssetTab;
