import React from "react";
import {
  capitalizeFirstLetter,
  formatDate,
} from "../../AssetDetails/Tabs/FormatDate";
import AssetDetailsTab from "../AssetDetailsTab";

const LeaseInfo = ({
  leaseSpecificDetails,
  asset_id,
  leaseId,
  runDuration,
}) => {
  return (
    <>
      <div
        className="flex flex-col md:flex-row 
            text-xs w-[93vw]  justify-between  mt-2 gap-4 dark:bg-slate-900  space-y-2 md:space-y-0"
      >
        {/* Frame 1 */}
        <div className="flex-1 border bg-white px-4 text-[10px] rounded-[4px] shadow-md ">
          <div className="py-2">
            <span className="text-gray-500">RSO Id</span>
            <span
              className={`block font-semibold text-x[10px]
               `}
            >
              {capitalizeFirstLetter(leaseSpecificDetails?.odoo_order_id)}
            </span>
          </div>
          <div className="py-2">
            <span className="text-gray-500">Asset No</span>
            <span
              className={`block font-semibold text-[10px] ${leaseSpecificDetails?.device_id ? 'text-green-1': 'text-dark-6'}
               `}
            >
              {/* AvijitDa */}
              {leaseSpecificDetails?.asset_no}
            
            </span>
          </div>
        </div>

        <div className="flex-1 border bg-white px-4 text-[10px] rounded-[4px] shadow-md ">
          <div className="py-2">
            <span className="text-gray-500">Status</span>
            <span
              className={`block font-semibold text-[10px] 
                ${
                  leaseSpecificDetails?.lease_status === "inactive"
                    ? "text-gray-500"
                    : leaseSpecificDetails?.lease_status === "never assigned"
                    ? "text-red-600"
                    : "text-green-500"
                }`}
            >
              {capitalizeFirstLetter(leaseSpecificDetails?.lease_status)}
            </span>
          </div>
          <div className="py-2">
            <span className="text-gray-500">Lease Type</span>
            <span
              className={`block font-semibold text-[10px] 
               `}>
              {/* {leaseSpecificDetails?.lease_type?  leaseSpecificDetails?.lease_type: "N/A" } */}
              {leaseSpecificDetails?.lease_type? leaseSpecificDetails?.lease_type === "wet_lease" ? "Wet Lease": leaseSpecificDetails?.lease_type === "customer_certified" ?  "Customer Certified" : leaseSpecificDetails?.lease_type === "dry_lease"? "Dry Lease" : leaseSpecificDetails?.lease_type === "nill"? "N/A" : "N/A":"N/A"}
               {/* {console.log(leaseSpecificDetails?.lease_type)} */}
            </span>
          </div>
        </div>

        {/* Frame 2 */}
        <div className="flex-1 border bg-white px-4 text-[10px] rounded-[4px] shadow-md ">
          <div className="py-2">
            <span className="text-gray-500">Rental Start Date</span>
            <span className="block font-semibold text-xs">
              {formatDate(leaseSpecificDetails?.rental_start_date)}
            </span>
          </div>
          <div className="py-2">
            <span className="text-gray-500">Customer Name</span>
            <span
              className={`block font-semibold text-[10px] 
               `}
            >
              {/* AvijitDa */}
              {leaseSpecificDetails?.customer}
            </span>
          </div>
        </div>

        {/* Frame 3 */}
        <div className="flex-1 border bg-white px-4 text-[10px] rounded-[4px] shadow-md ">
          <div className="py-2">
            <span className="text-gray-500"> Rental End Date:</span>
            <span className="block font-semibold text-[10px]">
              {leaseSpecificDetails?.closed_date ? leaseSpecificDetails?.closed_date : leaseSpecificDetails?.rental_extended_date? formatDate(leaseSpecificDetails?.rental_extended_date) : formatDate(leaseSpecificDetails?.rental_end_date)}
            </span>
          </div>
          <div className="py-2">
            <span className="text-gray-500">
              Runtime Duration (Till last update)
            </span>
            <span
              className={`block font-semibold text-[10px] 
               `}
            >
              {/* AvijitDa */}
              {runDuration === null || undefined ? (
                <div className="w-[67px] h-7 bg-[#EEE] border rounded-md animate-pulse" />
              ) : (
                runDuration
              )}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaseInfo;
