import { Tooltip } from '@mui/material'
import React from 'react'

const TooltipFun = ({children, title, placement}) => {
  return (
    <Tooltip title={title} placement={placement}
    enterDelay={700} 
    leaveDelay={50} 
    >
      {children}
    </Tooltip>
  )
}

export default TooltipFun
