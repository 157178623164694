import React from "react";

const UpdateForm = ({
  title,
  keyValueType,
  type,
  value,
  formData,
setFormData,
}) => {
  return (
    <>
      {title === "Asset No." || title === "RSO No" ? (
        <div className="text-[12px] flex flex-col items-left justify-between ">
          <h1>{title}</h1>
          <select
            onChange={(e) => setFormData({...formData, asset_no: e.target.value})}
            className="w-[400px] text-[12px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
          >
            <option value={value}>{value}</option>
          </select>
        </div>
      ) : (
        <div className="flex flex-col justify-between items-left text-[12px]">
          <h1>{title}</h1>
          <input
            onChange={(e) => setFormData({...formData, lease_RSO_nos: e.target.value})}
            type={type || "text"}
            value={value}
            placeholder={title}
            className="w-[400px] text-[12px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
          />
        </div>
      )}
    </>
  );
};

export default UpdateForm;
