import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import NewForm from "./components/NewForm";
import { setBaseUrl } from "../../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const NewLogistics = () => {
  const [checkInternalTransfer, setCheckInternalTransfer] = useState(false);
  const [lease, setLease] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [formErrors, setFormErrors] = useState(false)

  const getRsoId = async () => {
    try {
      const res = await fetch(`${setBaseUrl}/lease/get_all`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });
      const data = await res.json();

      //   console.log("API response data:", data); // Log the full data response for inspection

      // Extract order_odoo_id using optional chaining to prevent undefined values
      //   const rsoIds = data.map((item) => item?.order_odoo_id).filter(Boolean); // Filters out undefined values
      setLease(data);
    } catch (error) {
      console.error("Error fetching RSO IDs:", error);
    }
  };
  useEffect(() => {
    getRsoId();
    setDataFetched(true);
  }, []);
  let RSO_No = [];
  let Asset_No = [];

  for (let i = 0; i < lease.length; i++) {
    // if (lease[i].lease_status === "inactive") {
    //   RSO_No.push({
    //     value: lease[i].odoo_order_id,
    //     label: lease[i].odoo_order_id,
    //   });
    // }

    RSO_No.push({
      value: lease[i].odoo_order_id,
      label: lease[i].odoo_order_id,
    });
  }

  for (let i = 0; i < lease.length; i++) {
    Asset_No.push({
      value: lease[i].asset_id,
      label: lease[i].asset_no,
    });
  }

  // Array to store multiple logistic entries
  const [logisticsList, setLogisticsList] = useState([
    {
      mob_demob_state: "",
      date_of_dispatch: "",
      origin: "",
      courier_company: "",
      transporter_bill_no: "", //
      transporter_bill_date: "", //

      awb_number: "",
      total_km: "",
      bill_amount: "", //
      transportation_cost: "",
      amount_paid_by_customer: "", //
      rate_per_km: "", //

      arrival_date: "",
      destination: "",

      select_multiple_rso: "",
      dc_no: "", //
      dc_date: "", //
      truck_no: "", //
      truck_ft: "", //
      mob_as_per_invoice: "", //
      demob_as_per_invoice: "", //
    },
  ]);

  const handleFormChange = (index, key, value) => {
    // Update specific form data in logisticsList by index
    const updatedList = [...logisticsList];
    updatedList[index] = { ...updatedList[index], [key]: value };
    setLogisticsList(updatedList);
  };

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(false);
    const logisticsData = logisticsList[0];

    if(!logisticsData.select_multiple_rso) {
    toast.error("Please Select RSO Number")
    setFormErrors(true)
      return;
    }

    try {
      const { data } = await axios.post(
        `${setBaseUrl}/lease/create-logistic`,
        logisticsData,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": sessionStorage.getItem("token"),
          },
        }
      );

      // Handle successful response
      toast.success("Logistics created successfully");
      navigate(`/logistics`);
    } catch (error) {
      setLoading(true);
      console.error("Logistics creation failed:", error);
      toast.error("Logistics creation failed");
    }
  };

  return (
    <Layout>
      <form
        onSubmit={handleSubmit}
        className="text-xs space-y-2 p-5  mt-2 bg-white rounded-[4px] shadow-md"
      >
        <div className="flex justify-between">
          <h1 className="text-text-1 font-extrabold text-xs">
            Create New Logistics
          </h1>
        </div>
        {logisticsList.map((logistic, index) => (
          <div
            key={index}
            className="flex justify-start gap-10 md:flex-row flex-col px-10 py-5"
          >
            <div className="flex flex-col gap-2">
              <div className="flex gap-5 text-[10px]  focus:outline-none focus:ring-0 pb-2">
                Is it internal transfer?
                <input
                  type="checkbox"
                  className=""
                  onChange={(e) => setCheckInternalTransfer(e.target.checked)}
                />
              </div>
              {!checkInternalTransfer ? (
                <>
                  <NewForm
                    title="Select Multiple RSO"
                    keyValueType="select_multiple_rso"
                    newLogistic={logistic}
                    RSO_No={RSO_No}
                    getRsoId={getRsoId}
                    dataFetched={dataFetched}
                    setDataFetched={setDataFetched}
                    setNewLogistic={(updatedLogistic) =>
                      handleFormChange(
                        index,
                        "select_multiple_rso",
                        updatedLogistic.select_multiple_rso
                      )
                    }
                    formErrors={formErrors}
                    setFormErrors={setFormErrors}
                  />
                </>
              ) : (
                <>
                  <NewForm
                    title="Asset Number"
                    keyValueType="asset_no"
                    newLogistic={logistic}
                    Asset_No={Asset_No}
                    getRsoId={getRsoId}
                    dataFetched={dataFetched}
                    setDataFetched={setDataFetched}
                    setNewLogistic={(updatedLogistic) =>
                      handleFormChange(
                        index,
                        "asset_no",
                        updatedLogistic.asset_no
                      )
                    }
                  />
                </>
              )}

              <NewForm
                title="Mob Demob State"
                keyValueType="mob_demob_state"
                newLogistic={logistic}
                setNewLogistic={(updatedLogistic) =>
                  handleFormChange(
                    index,
                    "mob_demob_state",
                    updatedLogistic.mob_demob_state
                  )
                }
              />
              <NewForm
                title="Date of Dispatch"
                type="date"
                keyValueType="date_of_dispatch"
                newLogistic={logistic}
                setNewLogistic={(updatedLogistic) =>
                  handleFormChange(
                    index,
                    "date_of_dispatch",
                    updatedLogistic.date_of_dispatch
                  )
                }
              />
              <NewForm
                title="Enter Source Location"
                type="text"
                placeholder="e.g. Chennai"
                keyValueType="origin"
                newLogistic={logistic}
                setNewLogistic={(updatedLogistic) =>
                  handleFormChange(index, "origin", updatedLogistic.origin)
                }
              />
              <NewForm
                title="Transporter Name"
                type="text"
                placeholder="e.g. Genie"
                keyValueType="courier_company"
                newLogistic={logistic}
                setNewLogistic={(updatedLogistic) =>
                  handleFormChange(
                    index,
                    "courier_company",
                    updatedLogistic.courier_company
                  )
                }
              />
              <NewForm
                title="Transporter Bill No"
                type="text"
                placeholder="e.g. 12BFN231"
                keyValueType="transporter_bill_no"
                newLogistic={logistic}
                setNewLogistic={(updatedLogistic) =>
                  handleFormChange(
                    index,
                    "transporter_bill_no",
                    updatedLogistic.transporter_bill_no
                  )
                }
              />

              <NewForm
                title="Transporter Bill Date"
                type="date"
                keyValueType="transporter_bill_date"
                newLogistic={logistic}
                setNewLogistic={(updatedLogistic) =>
                  handleFormChange(
                    index,
                    "transporter_bill_date",
                    updatedLogistic.transporter_bill_date
                  )
                }
              />
              <NewForm
                title="Enter a valid E-Way Bill Number"
                type="text"
                placeholder="e.g. MNEH12321"
                keyValueType="awb_number"
                newLogistic={logistic}
                setNewLogistic={(updatedLogistic) =>
                  handleFormChange(
                    index,
                    "awb_number",
                    updatedLogistic.awb_number
                  )
                }
              />

              <NewForm
                title="Total Distance (Km)"
                type="number"
                placeholder="e.g. 12 k.m."
                keyValueType="total_km"
                min={0} // Ensure value is not less than 0
                newLogistic={logistic}
                setNewLogistic={(updatedLogistic) => {
                  const totalKm = Math.max(updatedLogistic.total_km, 0); // Ensure no negative values
                  handleFormChange(index, "total_km", totalKm);
                }}
              />
              <NewForm
                title="Bill Amount (₹)"
                type="number"
                placeholder="e.g. ₹1,00,000/-"
                keyValueType="bill_amount"
                min={0} // Ensure value is not less than 0
                newLogistic={logistic}
                setNewLogistic={(updatedLogistic) => {
                  const billAmount = Math.max(updatedLogistic.bill_amount, 0); // Ensure no negative values
                  handleFormChange(index, "bill_amount", billAmount);
                }}
              />
            </div>

            <div className="flex flex-col gap-2">
              {/* <NewForm
                title="Total Km"
                type="number"
                keyValueType="total_km"
                newLogistic={logistic}
                setNewLogistic={(updatedLogistic) =>
                  handleFormChange(index, "total_km", updatedLogistic.total_km)
                }
              />
              <NewForm
                title="Bill Amount"
                type="number"
                keyValueType="bill_amount"
                newLogistic={logistic}
                setNewLogistic={(updatedLogistic) =>
                  handleFormChange(
                    index,
                    "bill_amount",
                    updatedLogistic.bill_amount
                  )
                }
              />
              <NewForm
                title="Transportation Cost"
                type="number"
                keyValueType="transportation_cost"
                newLogistic={logistic}
                setNewLogistic={(updatedLogistic) =>
                  handleFormChange(
                    index,
                    "transportation_cost",
                    updatedLogistic.transportation_cost
                  )
                }
              />
              <NewForm
                title="Amount Paid By Customer"
                type="number"
                keyValueType="amount_paid_by_customer"
                newLogistic={logistic}
                setNewLogistic={(updatedLogistic) =>
                  handleFormChange(
                    index,
                    "amount_paid_by_customer",
                    updatedLogistic.amount_paid_by_customer
                  )
                }
              />
              <NewForm
                title="Rate/KM"
                type="number"
                keyValueType="rate_per_km"
                newLogistic={logistic}
                setNewLogistic={(updatedLogistic) =>
                  handleFormChange(
                    index,
                    "rate_per_km",
                    updatedLogistic.rate_per_km
                  )
                }
              /> */}

              <NewForm
                title="Transportation Cost (₹)"
                type="number"
                keyValueType="transportation_cost"
                placeholder="e.g. ₹1,00,000/-"
                min={0} // Ensure value is not less than 0
                newLogistic={logistic}
                setNewLogistic={(updatedLogistic) => {
                  const transportationCost = Math.max(
                    updatedLogistic.transportation_cost,
                    0
                  ); // Ensure no negative values
                  handleFormChange(
                    index,
                    "transportation_cost",
                    transportationCost
                  );
                }}
              />
              <NewForm
                title="Amount Paid By Customer (₹)"
                type="number"
                placeholder="e.g. ₹1,00,000/-"
                keyValueType="amount_paid_by_customer"
                min={0} // Ensure value is not less than 0
                newLogistic={logistic}
                setNewLogistic={(updatedLogistic) => {
                  const amountPaidByCustomer = Math.max(
                    updatedLogistic.amount_paid_by_customer,
                    0
                  ); // Ensure no negative values
                  handleFormChange(
                    index,
                    "amount_paid_by_customer",
                    amountPaidByCustomer
                  );
                }}
              />
              <NewForm
                title="Rate/KM"
                type="number"
                placeholder={"e.g. 30/km"}
                keyValueType="rate_per_km"
                min={0} // Ensure value is not less than 0
                newLogistic={logistic}
                setNewLogistic={(updatedLogistic) => {
                  const ratePerKm = Math.max(updatedLogistic.rate_per_km, 0); // Ensure no negative values
                  handleFormChange(index, "rate_per_km", ratePerKm);
                }}
              />

              <NewForm
                title="Arrival Date"
                type="date"
                keyValueType="arrival_date"
                newLogistic={logistic}
                setNewLogistic={(updatedLogistic) =>
                  handleFormChange(
                    index,
                    "arrival_date",
                    updatedLogistic.arrival_date
                  )
                }
              />
              <NewForm
                title="Destination"
                type="text"
                placeholder="e.g. Delhi"
                keyValueType="destination"
                newLogistic={logistic}
                // setNewLogistic={(updatedLogistic) => {
                //   const destinationData = Math.max(
                //     updatedLogistic.destination,
                //     0
                //   ); // Ensure no negative values
                //   handleFormChange(index, "destination", destinationData);
                // }}
                setNewLogistic={(updatedLogistic) =>
                  handleFormChange(
                    index,
                    "destination",
                    updatedLogistic.destination
                  )
                }
              />

              <NewForm
                title="DC No:"
                type="number"
                placeholder="e.g. 12345ABC"
                keyValueType="dc_no"
                newLogistic={logistic}
                // setNewLogistic={(updatedLogistic) =>
                //   handleFormChange(index, "dc_no", updatedLogistic.dc_no)
                // }
                setNewLogistic={(updatedLogistic) => {
                  const dcNo = Math.max(updatedLogistic.dc_no, 0); // Ensure no negative values
                  handleFormChange(index, "dc_no", dcNo);
                }}
              />

              <NewForm
                title="DC Date"
                type="date"
                keyValueType="dc_date"
                newLogistic={logistic}
                setNewLogistic={(updatedLogistic) =>
                  handleFormChange(index, "dc_date", updatedLogistic.dc_date)
                }
              />

              <NewForm
                title="Truck No"
                type={"text"}
                placeholder="e.g. MS123"
                keyValueType="truck_no"
                newLogistic={logistic}
                setNewLogistic={(updatedLogistic) =>
                  handleFormChange(index, "truck_no", updatedLogistic.truck_no)
                }
              />

              <NewForm
                title="Truck Ft"
                keyValueType="truck_ft"
                placeholder="e.g. 12 ft"
                newLogistic={logistic}
                setNewLogistic={(updatedLogistic) =>
                  handleFormChange(index, "truck_ft", updatedLogistic.truck_ft)
                }
              />

              <NewForm
                title="MOB/Invoice (₹) "
                placeholder="e.g. ₹12,000/-"
                keyValueType="mob_as_per_invoice"
                newLogistic={logistic}
                setNewLogistic={(updatedLogistic) =>
                  handleFormChange(
                    index,
                    "mob_as_per_invoice",
                    updatedLogistic.mob_as_per_invoice
                  )
                }
              />
              <NewForm
                title="De-MOB/Invoice (₹)"
                placeholder="e.g. ₹10,000/-"
                keyValueType="demob_as_per_invoice"
                newLogistic={logistic}
                setNewLogistic={(updatedLogistic) =>
                  handleFormChange(
                    index,
                    "demob_as_per_invoice",
                    updatedLogistic.demob_as_per_invoice
                  )
                }
              />
            </div>
          </div>
        ))}
        <div className="flex gap-2">
          {/* Back Button */}
          <button
            onClick={() => navigate("/logistics")}
            className="bg-white text-[10px] h-7 w-[96px] shadow-md border"
          >
            Back
          </button>
          {/* Submit Button */}
          <button
            type="submit"
            className=" shadow-md text-white bg-blue-2 px-3 text-xs h-7 w-[96px] rounded-[2px] transition"
          >
            Submit
          </button>
        </div>
      </form>
    </Layout>
  );
};

export default NewLogistics;
