const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
}

export const FormPercentage = (number1, number2) => {
  let result = (number1 / number2) * 100;
  return parseFloat(result).toFixed(2);
};

export function capitalizeFirstLetter(string) {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
}

export const currency = (number) => {
  let floatNumber;


  try {
    if (number === ''){
        throw Error("Not a number")
    }
    floatNumber = parseFloat(number);
  } catch (error) {
    floatNumber = 0;
  }

  var  formatter = new Intl.NumberFormat("en-IN");
  var formattedNumber = formatter.format(floatNumber);
  if (formattedNumber.split(".").length <= 1) formattedNumber = formattedNumber + ".00"
  else if (formattedNumber.split(".")[1].length == 1) formattedNumber = formattedNumber + "0"
  // var formattedNumber = numbe
 
  
  return formattedNumber;
  // Output: 1,234,567.89
};

export const customerName = (name) => {
  const item_1 = name.split(" - ");
  let item2 = item_1[1];

  return item2;
};

export const customerGST = (name) => {
  const item_1 = name.split("-");
  let item2 = item_1[0];

  return item2;
};

export const formatAadhaar = (number) => {
  let aadhaarNumber = number.toString().replace(/\d{4}(?=\d)/g, "$& ");
  return aadhaarNumber;
};

export const formatDateForInput = (date) => {
  if (!date) return null;
  return new Date(date).toISOString().split("T")[0]; // Returns 'YYYY-MM-DD'
};

export const formatMonth = (number) => {
  let [year, month] = number.split("-");

  let formattedMonth = monthNames[parseInt(month, 10) - 1];
  return { formattedMonth: formattedMonth, year: year };
};



export function convertToHoursAndMinutes(decimalNumber) {
  // Get the whole number part for hours
  const hours = Math.floor(decimalNumber);

  // Get the decimal part and convert it to minutes
  const minutes = Math.round((decimalNumber - hours) * 60);

  return `${hours}h ${minutes}min`;
}

export function currencyInLakhs(number) {
  // Initial Function for Lakhs and Thousands
  // let lakhs
  // let thousands, total
  // if(number >= 100000) {
  //   lakhs = number / 100000;
  
  //   return lakhs 
  // } else {
  //   thousands = number / 1000;
   
  //   return thousands
  // }


  // Updated function when Ojas Sir said to be shown only Thousands
  if(number >= 1000) {
    number = number / 1000
  }
  return number
}


export function dateValue(number) {
  let date = new Date(number * 1000); 
  let data1 = date.toLocaleString().split(",")[0]
  let day = data1.split("/")[0]
  let month = data1.split("/")[1]
  let year = data1.split("/")[2]
  let date2 = day + "-" + month + "-" + year 
  return date2
}


export function formatDateInput(dateString) {
  if (!dateString) return ""; // Return an empty string if dateString is undefined or empty

  const date = new Date(dateString);
  if (isNaN(date.getTime())) return ""; // Return an empty string if date is invalid

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}



export const formatTime = (time) => {
  let newTime 

  newTime = time.split(" ")[1]
  return newTime.split(".")[0]
}


export const formatTimeDuration = (time) => {
  let newTime

  newTime = time.split(".")[0]
  return newTime
}

export const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  
  // Extract day, month, year, and time
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${day}-${month}-${year} at ${hours}:${minutes}`;
};