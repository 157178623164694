import React from "react";


function Loader() {
  return (
    
    <div  className='border h-64 p-5 rounded mt-4 shadow-2xl w-[100%] max-w-full mb-5 animate-pulse '>
           <div className="animate-pulse">
            <div className=" h-10 bg-gray-400  mb-4"></div>
            <div className="h-10 bg-gray-300  mb-4"></div>
            <div className="h-10 bg-gray-300  mb-4"></div>
            <div className="h-10 bg-gray-300  mb-4"></div>
          </div>
    </div>

    
//     <div className="items-center flex justify-center p-40">
//     <img
//   src="/videos/best_animations.gif"
//   alt="Description of GIF"
// className="w-[150px] h-[100px]"
//     />
//     </div>
  );
}

export default Loader;


// import React from 'react';
// import './loader.css';  // For custom keyframes

// const Loader = () => {
//   return (
//     <div className="flex justify-center items-center h-screen bg-black">
//       <div className="relative w-[20rem] h-[20rem]">
//         {/* Circle box animation */}
//         <div className="absolute w-1/2 h-1/2 overflow-hidden top-0 left-0">
//           <div className="absolute w-full h-full rounded-full opacity-10 bg-blue-500 animate-lightMe1"></div>
//         </div>
//         <div className="absolute w-1/2 h-1/2 overflow-hidden top-0 right-0">
//           <div className="absolute w-full h-full rounded-full opacity-10 bg-green-500 animate-lightMe2"></div>
//         </div>
//         <div className="absolute w-1/2 h-1/2 overflow-hidden bottom-0 right-0">
//           <div className="absolute w-full h-full rounded-full opacity-10 bg-yellow-500 animate-lightMe3"></div>
//         </div>
//         <div className="absolute w-1/2 h-1/2 overflow-hidden bottom-0 left-0">
//           <div className="absolute w-full h-full rounded-full opacity-10 bg-red-500 animate-lightMe4"></div>
//         </div>

//         {/* Text "loading..." */}
//         <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[40px] h-[40px] overflow-hidden">
//           <div className="relative w-[40px] h-[40px] leading-[40px] animate-slider">
//             {['L', 'O', 'A', 'D', 'I', 'N', 'G', '...'].map((val, index) => (
//               <span
//                 key={index}
//                 className="block w-[40px] h-[40px] leading-[40px] text-center text-white text-2xl"
//               >
//                 {val}
//               </span>
//             ))}
//           </div>
//         </div>

//         {/* Loader Text */}
//         <div className="text-white mt-2 text-lg opacity-20 animate-blink">wait please</div>
//       </div>
//     </div>
//   );
// };

// export default Loader;
