import { Button, Input } from "@mui/material";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { setBaseUrl } from "../../../config";

const EditableLogTable = ({ monthData, setEditable, asset_no }) => {
  // console.log("Asset No", asset_no);
  const [formData, setFormData] = useState([]);
  const [submittedData, setSubmittedData] = useState([
    
  ]);

  useEffect(() => {
    setFormData(monthData);
  }, [monthData]);

  const handleInputChange = (index, field, value) => {
    
    // let  updatedFormData =[]
    if(formData[index].x_studio_time_in && formData[index].x_studio_time_out){
      if(formData[index].x_studio_time_in > formData[index].x_studio_time_out){
        toast.error("Time In should be less than Time Out");
        // return;
      }  else if(formData[index].x_studio_time_in === formData[index].x_studio_time_out){
        toast.error("Time In should not be equal to Time Out");
        // return;
      }
    }
    // Update the formData for the specific field
    const updatedFormData = [...formData];
    updatedFormData[index][field] = value;

    // Update formData state
    // setFormData(updatedFormData);

    // Update or add the row to submittedData
    const updatedRow = updatedFormData[index];
    setSubmittedData((prevData) => {
      const existingIndex = prevData.findIndex(
        (data) => data.x_studio_date === updatedRow.x_studio_date
      );

      if (existingIndex >= 0) {
        // Update existing row
        const updatedData = [...prevData];
        updatedData[existingIndex] = {
          ...updatedData[existingIndex],
          ...updatedRow,
        };
        return updatedData;
      } else {
        // Add new row
        return [...prevData, { ...updatedRow }];
      }
    });
  };

const handleSave = async (e) => {
    e.preventDefault();
  // Define or pass the asset_no here
    const list = [
        "x_studio_date",
        "x_studio_day_type",
        "x_studio_time_in",
        "x_studio_time_out",
        "x_studio_breakdown_time",
        "x_studio_overtime",
        "x_studio_reimbursements",
        "x_studio_rental_register_no",
        "x_name",
        "odoo_id",
    ];
    let SubmissionData = [];

    for (let i = 0; i < submittedData.length; i++) {
        const objData = submittedData[i];
        let obj = {};
        for (let j = 0; j < Object.keys(objData).length; j++) {
            let objectKey = Object.keys(objData)[j];
            if (objectKey === "x_studio_operator") {
                obj["x_studio_operator1"] = submittedData[i][objectKey];
            }
           
            if (list.includes(objectKey)) {
                obj[objectKey] = submittedData[i][objectKey];
            } else if(list.includes("x_name")){
                obj["x_name"] = asset_no;
            }
        }
        SubmissionData.push(obj);
    }

    // console.log("SubmissionData", SubmissionData);

    // Uncomment and complete the fetch request if necessary
    try {
      const response = await fetch(`${setBaseUrl}/lease/entry-timesheet-data`, {
        method: "POST",
        body: JSON.stringify({
          input_arr: SubmissionData,
          action: "replace",
        }),
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });

      if (response.ok) {
        toast.success("Data updated successfully!");
      } else {
        throw new Error("Failed to update data");
      }
    } catch (error) {
      console.error(error);
      toast.error("Data not updated successfully. Please check odoo logs and try again!!");
    }
};


  return (
    <div>
      <table className="w-full table-auto text-center bg-white">
        <thead className="border-b">
          <tr>
            <th className="p-2">Date</th>
            <th className="p-2">Day Type</th>
            <th className="p-2">Time In</th>
            <th className="p-2">Time Out</th>
            <th className="p-2">Total Time</th>
            <th className="p-2">Overtime</th>
            <th className="p-2">Time Adjust</th>
            <th className="p-2">Odoo Bill Amount</th>
            <th className="p-2">Overtime Amount</th>
            <th className="p-2">Operator</th>
          </tr>
        </thead>
        <tbody>
          {formData.map((item, i) => (
            <tr key={i} className="border-b">
              <td className="p-2">
                <input
                  type="date"
                  value={item.x_studio_date || ""}
                  onChange={(e) =>
                    handleInputChange(i, "x_studio_date", e.target.value)
                  }
                  className="w-full text-center focus:outline-none text-[10px]"
                />
              </td>
              <td className="p-2">
                <select
                  value={item.x_studio_day_type || ""}
                  onChange={(e) => {
                    handleInputChange(i, "x_studio_day_type", e.target.value);
                    // setSubmittedData({...submittedData, x_studio_day_type:e.target.value})
                  }}
                  className="w-full text-center focus:outline-none text-[10px]"
                >
                  <option value="" disabled>
                    Select Day Type
                  </option>
                  <option value="dry">dry</option>
                  <option value="wet">wet</option>
                </select>
              </td>
              <td className="p-2">
                <input
                  type="number"
                  value={item.x_studio_time_in || ""}
                  onChange={(e) => {
                    handleInputChange(i, "x_studio_time_in", e.target.value);
                    // setSubmittedData({...submittedData, x_studio_time_in:e.target.value})
                  }}
                  className="w-full text-center focus:outline-none text-[10px]"
                />
              </td>
              <td className="p-2">
                <input
                  type="number"
                  value={item.x_studio_time_out || ""}
                  onChange={(e) => {
                    handleInputChange(i, "x_studio_time_out", e.target.value);
                    // setSubmittedData({...submittedData, x_studio_time_out:e.target.value})
                  }}
                  className="w-full text-center focus:outline-none text-[10px]"
                />
              </td>
              <td className="p-2">
                <input
                  type="number"
                  value={item.x_studio_total_time || ""}
                  onChange={(e) => {
                    handleInputChange(i, "x_studio_total_time", e.target.value);
                    // setSubmittedData({...submittedData, x_studio_total_time:e.target.value})
                  }}
                  className="w-full text-center focus:outline-none text-[10px]"
                />
              </td>
              <td className="p-2">
                <input
                  type="number"
                  value={item.x_studio_overtime || ""}
                  onChange={(e) => {
                    handleInputChange(i, "x_studio_overtime", e.target.value);
                    // setSubmittedData({...submittedData, x_studio_overtime: e.target.value})
                  }}
                  className="w-full text-center focus:outline-none text-[10px]"
                />
              </td>
              <td className="p-2">
                <input
                  type="number"
                  value={item.x_studio_time_adjust || ""}
                  onChange={(e) => {
                    handleInputChange(
                      i,
                      "x_studio_time_adjust",
                      e.target.value
                    );
                    // setSubmittedData({...submittedData, x_studio_time_adjust: e.target.value})
                  }}
                  className="w-full text-center focus:outline-none text-[10px]"
                />
              </td>
              <td className="p-2">
                <input
                  type="number"
                  value={item.x_studio_normal_bill_amount || ""}
                  onChange={(e) => {
                    handleInputChange(
                      i,
                      "x_studio_normal_bill_amount",
                      e.target.value
                    );
                    // setSubmittedData({...submittedData, x_studio_normal_bill_amount: e.target.value})
                  }}
                  className="w-full text-right focus:outline-none text-[10px]"
                />
              </td>
              <td className="p-2">
                <input
                  type="number"
                  value={item.x_studio_overtime_amount || ""}
                  onChange={(e) => {
                    handleInputChange(
                      i,
                      "x_studio_overtime_amount",
                      e.target.value
                    );
                    // setSubmittedData({...submittedData, x_studio_overtime_amount: e.target.value})
                  }}
                  className="w-full text-right focus:outline-none text-[10px]"
                />
              </td>
              <td className="p-2">
                <Input
                  sx={{
                    fontSize: "10px",
                  }}
                  value={item.x_studio_operator || ""}
                  onChange={(e) => {
                    handleInputChange(i, "x_studio_operator", e.target.value);
                    // setSubmittedData({...submittedData, x_studio_operator: e.target.value})
                  }}
                  className="w-full text-center focus:outline-none text-[10px]"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-end gap-4 mt-2">
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setEditable(false)}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default EditableLogTable;


// Test- 2


// import { Button, Input } from "@mui/material";
// import React, { useState, useEffect } from "react";
// import { toast } from "react-toastify";
// import { setBaseUrl } from "../../../config";

// const EditableLogTable = ({ monthData, setEditable, asset_no }) => {
//   const [formData, setFormData] = useState([]);

//   useEffect(() => {
//     if (monthData?.length) {
//       const firstDate = new Date(monthData[0].x_studio_date);
//       const lastDate = new Date(
//         firstDate.getFullYear(),
//         firstDate.getMonth() + 1,
//         0
//       );
//       const allDates = [];

//       for (
//         let date = new Date(firstDate);
//         date <= lastDate;
//         date.setDate(date.getDate() + 1)
//       ) {
//         const dateString = date.toISOString().split("T")[0];
//         const existingData = monthData.find(
//           (item) => item.x_studio_date === dateString
//         );
//         allDates.push(
//           existingData || {
//             x_studio_date: dateString,
//             x_studio_day_type: "",
//             x_studio_time_in: "",
//             x_studio_time_out: "",
//             x_studio_total_time: "",
//             x_studio_overtime: "",
//             x_studio_time_adjust: "",
//             x_studio_normal_bill_amount: "",
//             x_studio_overtime_amount: "",
//             x_studio_operator: "",
//           }
//         );
//       }

//       setFormData(allDates);
//     }
//   }, [monthData]);

//   const handleInputChange = (index, field, value) => {
//     const updatedFormData = [...formData];
//     updatedFormData[index][field] = value;

//     if (
//       field === "x_studio_time_in" ||
//       field === "x_studio_time_out"
//     ) {
//       const { x_studio_time_in, x_studio_time_out } = updatedFormData[index];
//       if (x_studio_time_in && x_studio_time_out) {
//         if (x_studio_time_in > x_studio_time_out) {
//           toast.error("Time In should be less than Time Out");
//         } else if (x_studio_time_in === x_studio_time_out) {
//           toast.error("Time In should not be equal to Time Out");
//         }
//       }
//     }

//     setFormData(updatedFormData);
//   };

//   const handleSave = async (e) => {
//     e.preventDefault();
//     const list = [
//       "x_studio_date",
//       "x_studio_day_type",
//       "x_studio_time_in",
//       "x_studio_time_out",
//       "x_studio_total_time",
//       "x_studio_overtime",
//       "x_studio_time_adjust",
//       "x_studio_normal_bill_amount",
//       "x_studio_overtime_amount",
//       "x_studio_operator",
//     ];
//     const SubmissionData = formData.map((row) => {
//       const obj = {};
//       list.forEach((key) => {
//         obj[key] = row[key];
//       });
//       obj["x_name"] = asset_no;
//       return obj;
//     });

    
//     console.log("SubmissionData", SubmissionData);
//     // try {
//     //   const response = await fetch(`${setBaseUrl}/lease/entry-timesheet-data`, {
//     //     method: "POST",
//     //     body: JSON.stringify({
//     //       input_arr: SubmissionData,
//     //       action: "replace",
//     //     }),
//     //     headers: {
//     //       "Content-Type": "application/json",
//     //       "x-access-tokens": sessionStorage.getItem("token"),
//     //     },
//     //   });

//     //   if (response.ok) {
//     //     toast.success("Data updated successfully!");
//     //   } else {
//     //     throw new Error("Failed to update data");
//     //   }
//     // } catch (error) {
//     //   console.error(error);
//     //   toast.error(
//     //     "Data not updated successfully. Please check odoo logs and try again!!"
//     //   );
//     // }
//   };

//   return (
//     <div>
//       <table className="w-full table-auto text-center bg-white">
//         <thead className="border-b">
//           <tr>
//             <th className="p-2">Date</th>
//             <th className="p-2">Day Type</th>
//             <th className="p-2">Time In</th>
//             <th className="p-2">Time Out</th>
//             <th className="p-2">Total Time</th>
//             <th className="p-2">Overtime</th>
//             <th className="p-2">Time Adjust</th>
//             <th className="p-2">Odoo Bill Amount</th>
//             <th className="p-2">Overtime Amount</th>
//             <th className="p-2">Operator</th>
//           </tr>
//         </thead>
//         <tbody>
//           {formData.map((item, i) => (
//             <tr key={i} className="border-b">
//               <td className="p-2">{item.x_studio_date}</td>
//               <td className="p-2">
//                 <select
//                   value={item.x_studio_day_type || ""}
//                   onChange={(e) =>
//                     handleInputChange(i, "x_studio_day_type", e.target.value)
//                   }
//                   className="w-full text-center focus:outline-none text-[10px]"
//                 >
//                   <option value="" disabled>
//                     Select Day Type
//                   </option>
//                   <option value="dry">Dry</option>
//                   <option value="wet">Wet</option>
//                 </select>
//               </td>
//               <td className="p-2">
//                 <input
//                   type="number"
//                   value={item.x_studio_time_in || ""}
//                   onChange={(e) =>
//                     handleInputChange(i, "x_studio_time_in", e.target.value)
//                   }
//                   className="w-full text-center focus:outline-none text-[10px]"
//                 />
//               </td>
//               <td className="p-2">
//                 <input
//                   type="number"
//                   value={item.x_studio_time_out || ""}
//                   onChange={(e) =>
//                     handleInputChange(i, "x_studio_time_out", e.target.value)
//                   }
//                   className="w-full text-center focus:outline-none text-[10px]"
//                 />
//               </td>
//                <td className="p-2">
//                  <input
//                    type="number"
//                    value={item.x_studio_total_time || ""}
//                    onChange={(e) => {
//                      handleInputChange(i, "x_studio_total_time", e.target.value);
//                      // setSubmittedData({...submittedData, x_studio_total_time:e.target.value})
//                    }}
//                    className="w-full text-center focus:outline-none text-[10px]"
//                  />
//                </td>
//                <td className="p-2">
//                 <input
//                   type="number"
//                   value={item.x_studio_overtime || ""}
//                   onChange={(e) => {
//                     handleInputChange(i, "x_studio_overtime", e.target.value);
//                     // setSubmittedData({...submittedData, x_studio_overtime: e.target.value})
//                   }}
//                   className="w-full text-center focus:outline-none text-[10px]"
//                 />
//               </td>
//               <td className="p-2">
//                 <input
//                   type="number"
//                   value={item.x_studio_time_adjust || ""}
//                   onChange={(e) => {
//                     handleInputChange(
//                       i,
//                       "x_studio_time_adjust",
//                       e.target.value
//                     );
//                     // setSubmittedData({...submittedData, x_studio_time_adjust: e.target.value})
//                   }}
//                   className="w-full text-center focus:outline-none text-[10px]"
//                 />
//               </td>
//               <td className="p-2">
//                 <input
//                   type="number"
//                   value={item.x_studio_normal_bill_amount || ""}
//                   onChange={(e) => {
//                     handleInputChange(
//                       i,
//                       "x_studio_normal_bill_amount",
//                       e.target.value
//                     );
//                     // setSubmittedData({...submittedData, x_studio_normal_bill_amount: e.target.value})
//                   }}
//                   className="w-full text-right focus:outline-none text-[10px]"
//                 />
//               </td>
//               <td className="p-2">
//                 <input
//                   type="number"
//                   value={item.x_studio_overtime_amount || ""}
//                   onChange={(e) => {
//                     handleInputChange(
//                       i,
//                       "x_studio_overtime_amount",
//                       e.target.value
//                     );
//                     // setSubmittedData({...submittedData, x_studio_overtime_amount: e.target.value})
//                   }}
//                   className="w-full text-right focus:outline-none text-[10px]"
//                 />
//               </td>
//               <td className="p-2">
//                 <Input
//                   sx={{
//                     fontSize: "10px",
//                   }}
//                   value={item.x_studio_operator || ""}
//                   onChange={(e) => {
//                     handleInputChange(i, "x_studio_operator", e.target.value);
//                     // setSubmittedData({...submittedData, x_studio_operator: e.target.value})
//                   }}
//                   className="w-full text-center focus:outline-none text-[10px]"
//                 />
//               </td>
//               {/* Add other input fields similarly */}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <div className="flex justify-end gap-4 mt-2">
//         <Button variant="contained" color="primary" onClick={handleSave}>
//           Save
//         </Button>
//         <Button
//           variant="outlined"
//           color="secondary"
//           onClick={() => setEditable(false)}
//         >
//           Cancel
//         </Button>
//       </div>
//     </div>
//   );
// };

// export default EditableLogTable;



// //  test - 3 
