
import React from "react";
import TooltipFun from "../../TooltipFun";

const AssetTableHeader = ({
  assetDetails,
  activeCount,
  expiredCount,
  FreeAssetCount,
  soldAssets,
  trackerInstalledAssets,
}) => {
  const HeaderData = [
    {
      id: 1,
      title: "Total Assets",
      label: "The total number of assets currently registered in the system",
      data: assetDetails.length,
    },
    {
      id: 2,
      title: "Assets With Active Lease",
      label: "The number of assets currently under active lease agreements",
      data: activeCount,
    },
    {
      id: 3,
      title: "Assets With Expired Lease",
      label: "The number of assets with leases that have expired",
      data: expiredCount,
    },
    {
      id: 4,
      title: "Free Assets",
      label: "The number of assets available for lease or not in use",
      data: FreeAssetCount,
    },
    {
      id: 5,
      title: "Sold Assets",
      label: "The number of assets that have been sold",
      data: soldAssets,
    },
    {
      id: 6,
      title: "Tracker Installed Assets",
      label: "The number of assets with installed tracking devices",
      data: trackerInstalledAssets,
    },
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 p-4">
      {HeaderData.map((data) => (
        <TooltipFun key={data.id} placement="bottom" arrow title={data.label}>
          <div className="p-4 shadow-lg rounded-lg bg-gradient-to-r bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out cursor-pointer">
            <h2 className=" text-[10px] font-normal text-gray-700">{data.title}</h2>
            <p className="t text-base  font-extrabold text-dark-6 mt-2">{data.data}</p>
          </div>
        </TooltipFun>
      ))}
    </div>
  );
};

export default AssetTableHeader;
