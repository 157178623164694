import React, { useContext, useEffect, useState } from "react";
import Navbar from "./navbar/Navbar";
import logo from "../assets/images/logo.png";
import { Link, useLocation } from "react-router-dom";
import { NavbarContext } from "../Context/NavbarContext";
import { PermissionContext } from "../Context/PermissionsContext";
import webSiteTheme from "../utils/Theme";
import useAuthorities from "../utils/useAuthorities";
import EngineeringIcon from "@mui/icons-material/Engineering";
import MobileNav from "./navbar/MobileNav";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";

import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { FaInstalod } from "react-icons/fa6";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";

import { GiHamburgerMenu } from "react-icons/gi";
import NewAssetBtn from "./Buttons/NewAssetBtn";
import HistoryBtn from "./Buttons/HistoryBtn";
import MISReportBtn from "./Buttons/MISReportBtn";
import ExcelReportBtn from "./Buttons/ExcelReportBtn";
import { RiErrorWarningFill } from "react-icons/ri";
import ErrorIcon from '@mui/icons-material/Error';

function Layout({ pageNum, children, showCurrentTab, setShowCurrentTab }) {
  // const navigate = useNavigate();
  const [theme, setTheme] = useState("");
  const [navState, setNavState] = useContext(NavbarContext);
  // const [perms, setPerms] = useContext(PermissionContext);
  const [perms] = useContext(PermissionContext);
  const { assetsTabPerm, maintenanceTabPerm, leasesTabPerm, operatorsTabPerm } =
    useAuthorities();

  useEffect(() => {
    webSiteTheme();
    setTheme(sessionStorage.getItem("theme"));
  }, [theme]);

  const onClickTabChange = (number) => {
    console.log(number);
    setNavState(number);
  };

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  const [open, setOpen] = useState(false);

  // const NavData = ['Dashboard', 'Assets',  'Maintenance', 'Lease', 'Operators', 'Settings']
  const NavData = [
    {
      title: "Dashboard",
      route: "dashboard",
      imgUrl: HomeOutlinedIcon,
    },
    {
      title: "Assets",
      route: "assets",
      imgUrl: BusinessCenterOutlinedIcon,
    },
    {
      title: "Maintenance",
      route: "maintenance",
      imgUrl: ManageHistoryIcon,
    },
    {
      title: "Lease",
      route: "lease",
      imgUrl: FeedOutlinedIcon,
    },
    {
      title: "Operators",
      route: "operators",
      imgUrl: EngineeringIcon,
    },
    {
      title: "Settings",
      route: "settings",
      imgUrl: SettingsOutlinedIcon,
    },
    {
      title: "Logistics",
      route: "logistics",
      imgUrl: FaInstalod,
    },
    {
      title: 'Error Module',
      route: 'error-module',
      imgUrl: ErrorIcon
    }
  ];
  const path = useLocation().pathname;
  const [hover, setHover] = useState(false);

  return (
    <>
      <Navbar
        theme={theme}
        setTheme={setTheme}
        setOpen={setOpen}
        open={open}
        hover={hover}
      />
      <div className="flex ">
        <div
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          className={` h-full max-sm:hidden fixed ${
            hover ? `w-[200px]` : `w-[60px]`
          } z-[9999] transition-all ease-in-out duration-300 bg-white border-r dark:border-none dark:bg-gray-950 text-black shadow-2xl`}
        >
        
            <div className={`pl-3 flex mt-1 justify-start items-center transition-all translate-x-1 duration-300 ease-in-out  w-[300px] ${hover? 'opacity-100': 'opacity-0'}`}>
              <img className="w-8 mb-1" src={logo} alt="logo" />
              <p className=" font-semibold   text-lg text-dark-6">
                Asset <span className="font-semibold">Tracker</span>
              </p>
            </div>

          <div className=" flex flex-col gap-6  mt-16">
            {NavData.map((nav) => {
              const isActive = path.split("/")[1] === nav.route;

              return (
                <>
                  <Link
                    to={`/${nav.route}`}
                    key={nav.route}
                    className={` flex flex-rowb p-2  mx-4  items-center 
                      ${
                        hover
                          ? `  rounded-full `
                          : ` items-center justify-center `
                      }   
                      ${
                        isActive
                          ? "bg-blue-2  shadow-md text-white rounded-[6px]"
                          : "bg-transparent text-black dark:text-white "
                      }`}
                  >
                    <nav.imgUrl className="  fixed mx-1 h-6 w-6  p-0.5" />
                    <h1
                      className={`text-xs font-semibold  ${
                        hover
                          ? "opacity-100  ml-10 transition-all duration-500  ease-in-out"
                          : "opacity-0"
                      }`}
                    >
                      {nav.title}
                    </h1>
                  </Link>
                </>
              );
            })}
          </div>
        </div>

        <div className=" relative flex flex-col md:pl-[60px]  mt-[35px] h-full w-full">
          <div className="pt-2 xl:pt-5 px-3 flex-auto">
            <div className="tab-content tab-space">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Layout;
