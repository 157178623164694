import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function Loading({title}) {
  return (
    <Box sx={{ display: 'flex', }} >
        {title === "header" ? <CircularProgress size={10} ssx={{ color: '#000'}}/>: <CircularProgress  /> }
      
    </Box>
  );
}