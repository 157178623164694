import React from "react";
import { currency, formatDate } from "./FormatDate";
import moment from "moment/moment";
import { Tooltip } from "@mui/material";

const AssetCommercial = ({
  commercialDetails,
  custom_duty_date_of_clearance,
}) => {
  if ((commercialDetails === null)) {
    return <div className="text-xs text-red-1">No Commercial Details Added</div>;
  }

  // console.log(commercialDetails)

  // const TotalLandedCost = parseFloat(
  //   commercialDetails?.total_landed_cost
  // ).toFixed(2);

  // const TotalLandedCostWithGST = parseFloat(
  //   commercialDetails?.total_landed_cost_with_gst
  // ).toFixed(2);

  // const BOENum = commercialDetails?.boe_no;

  // const insurance =;
  let CommercialData = [];
if (commercialDetails) {

  CommercialData = [
    {
      title: "Total Landed Cost",
      label: `${
        commercialDetails.total_landed_cost === null || "nill"
          ? "N/A"
          : `₹${currency(
              parseFloat(commercialDetails?.total_landed_cost).toFixed(2)
            )}/-`
      }`,
      tooltip: "Total Landed Cost",
    },
    {
      title: "Ex-Works Price",
      label:
        commercialDetails?.exworks_price === null || "nill"
          ? "N/A"
          : `$${currency(commercialDetails?.exworks_price)}/-`,
      tooltip: "Ex-Works Price",
    },
    {
      title: "CIF Charges",
      label:
        commercialDetails?.cif_charges === null || "nill"
          ? "N/A"
          : `$${currency(commercialDetails?.cif_charges)}`,
      tooltip: "Cost, Insurance, and Freight (CIF) Charges",
    },
    {
      title: "Total Cost (Ex-Works + CIF)",
      label:
        commercialDetails?.total_cost === "nill" || null
          ? "N/A"
          : `$${currency(commercialDetails?.total_cost)}`,
      tooltip:
        "Total Cost = Ex Works (EXW) price + Cost, Insurance, and Freight (CIF) Charges",
    },
    {
      title: "Port of Clearance",
      label:
        commercialDetails?.port_of_clearance === null || "nill"
          ? "N/A"
          : commercialDetails?.port_of_clearance,
      tooltip: "Port of Clearance",
    },
    {
      title: "Custom Duty Date of Clearance",
      label:
        custom_duty_date_of_clearance === "" || "nill" || null
          ? "N/A"
          : custom_duty_date_of_clearance,
      tooltip: "Custom Duty Date of Clearance",
    },
    {
      title: "Purchase Order No",
      label:
        commercialDetails?.purchase_order_no === "nill" || null
          ? "N/A"
          : commercialDetails?.purchase_order_no,
      tooltip: "Purchase Order No",
    },
    {
      title: "Purchase Order Date",
      label: commercialDetails?.purchase_order_date
        ? "N/A"
        : moment(commercialDetails?.purchase_order_date).format("DD-MM-YYYY"),
      tooltip: "Purchase Order Date",
    },
    {
      title: "Insurance Renewal",
      label: commercialDetails?.insurance_renewal
        ? moment(commercialDetails?.insurance_renewal).format("DD-MM-YYYY")
        : "--",
      tooltip: "Insurance Renewal ",
    },
    {
      title: "Exchange Rate (at Remittance)",
      label:
        commercialDetails?.exchange_rate_rem === "nill" || null
          ? "N/A"
          : `1 USD = ${parseFloat(commercialDetails?.exchange_rate_rem).toFixed(
              2
            )} INR`,
      tooltip: "Exchange Rate of Remitted OEM",
    },
    {
      title: "Amount of Remitted OEM",
      label:
        commercialDetails?.amount_rem_to_oem === null || "nill"
          ? "N/A"
          : `₹${currency(
              parseFloat(commercialDetails?.amount_rem_to_oem).toFixed(2)
            )}/-`,
      tooltip: "Amount of Remitted OEM",
    },
    {
      title: "Date of Remittance to OEM",
      label:
        commercialDetails?.date_of_rem_to_oem === null || "nill"
          ? "N/A"
          : moment(commercialDetails?.date_of_rem_to_oem).format("DD-MM-YYY"),
      tooltip: "Date of Remittence to OEM",
    },
    {
      title: "BOE No",
      label:
        commercialDetails.boe_no === null || "nill"
          ? "N/A"
          : commercialDetails?.boe_no,
      tooltip: "Bill of entry (BOE)",
    },
    {
      title: "Exchange Rate (at BOE)",
      label:
        commercialDetails?.exrate_boe === null || "nill"
          ? "N/A"
          : `1 USD = ${parseFloat(commercialDetails?.exrate_boe).toFixed(
              2
            )}  INR`,
      tooltip: "Exchange rate at bill of entry",
    },
    {
      title: "Custom Duty Value",
      label:
        `${
          commercialDetails?.custom_duty_value === "nill" ||
          commercialDetails?.custom_duty_value === ""
            ? "N/A"
            : `₹${currency(commercialDetails?.custom_duty_value)}/-`
        }` || "N/A",
      tooltip: "Custom duty value",
    },
    {
      title: "Clearing Charges",
      label:
        commercialDetails?.clearing_charges === null || "nill"
          ? "N/A"
          : `₹${currency(commercialDetails?.clearing_charges)}/-`,
      tooltip: "Clearing Charges Amount",
    },
    {
      title: "CHA Charge",
      label:
        commercialDetails?.cha_charges === null || "nill"
          ? "N/A"
          : `₹${currency(commercialDetails?.cha_charges)}/-`,
      tooltip: "Customs House Agent (CHA) charge",
    },
    {
      title: "GST Amount",
      label: `${
        commercialDetails?.gst_amount === null || "nill"
          ? "N/A"
          : `₹${currency(commercialDetails?.gst_amount)}/-`
      }`,
      tooltip: "GST Amount",
    },
    {
      title: "Total Landed Cost with GST",
      label: `${
        commercialDetails?.total_landed_cost_with_gst === null || "nill"
          ? "N/A"
          : `₹${currency(
              parseFloat(commercialDetails?.total_landed_cost_with_gst).toFixed(
                2
              )
            )}/-`
      }`,
      tooltip: "Total landed cost with gst",
    },
    {
      title: "Transportation Charges (INR)",
      label:
        commercialDetails?.transportation_charges === null || "nill"
          ? "N/A"
          : `₹${currency(commercialDetails?.transportation_charges)}/-`,
      tooltip: "Transportation Charges",
    },
  ];
}

  return (
    <>
      <div className=" text-xs w-full">
        <h1 className="text-[#4b5563] font-extrabold mb-4">
          Asset Commercial Details
        </h1>
        {commercialDetails === null ? (
          "No Commercial Details Added"
        ) : (
          <div className="grid grid-cols-4 gap-2 ">
            {/* First column with first 6 items */}
            <div className="space-y-0 bg-white  shadow-md rounded-[4px]">
              {CommercialData?.slice(0, 6).map((item, index) => (
                <div
                  className=" flex justify-between flex-col h-[65px] py-5 px-3 items-start"
                  key={index}
                >
                  <Tooltip title={item.tooltip} placement="top">
                    <div className="font-normal">{item.title || "N/A"}</div>
                    <div
                      className={`flex justify-start font-semibold text-xs    w-full items-center ${
                        item.cssLabel || ""
                      }`}
                    >
                      {item.label}
                    </div>
                  </Tooltip>
                </div>
              ))}
            </div>

            {/* Second column */}
            <div className="space-y-0 bg-white  shadow-md rounded-[4px]">
              {CommercialData.slice(6, 11).map((item, index) => (
                <div
                  className=" flex justify-between flex-col h-[65px] py-5 px-3 items-start"
                  key={index}
                >
                  <Tooltip title={item.tooltip} placement="top">
                    <div className="font-normal">{item.title}</div>
                    <div
                      className={`flex justify-start  font-semibold text-xs    w-full items-center ${
                        item.cssLabel || ""
                      }`}
                    >
                      {item.label}
                    </div>
                  </Tooltip>
                </div>
              ))}
            </div>

            {/* Third column */}
            <div className="space-y-0 bg-white  shadow-md rounded-[4px]">
              {CommercialData.slice(11, 16).map((item, index) => (
                <div
                  className=" flex justify-between flex-col h-[65px] py-5 px-3 items-start"
                  key={index}
                >
                  <Tooltip title={item.tooltip} placement="top">
                    <div className="font-normal">{item.title}</div>
                    <div
                      className={`flex justify-start  font-semibold text-xs    w-full items-center ${
                        item.cssLabel || ""
                      }`}
                    >
                      {item.label}
                    </div>
                  </Tooltip>
                </div>
              ))}
            </div>

            {/* Fourth column */}
            <div className="space-y-0 bg-white  shadow-md rounded-[4px]">
              {CommercialData.slice(16).map((item, index) => (
                <div
                  className=" flex justify-between flex-col h-[65px] py-5 px-3 items-start"
                  key={index}
                >
                  <Tooltip title={item.tooltip} placement="top">
                    <div className="font-normal">{item.title}</div>
                    <div
                      className={`flex justify-start  font-semibold text-xs    w-full items-center ${
                        item.cssLabel || ""
                      }`}
                    >
                      {item.label}
                    </div>
                  </Tooltip>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AssetCommercial;
