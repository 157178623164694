import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-scroll";
import { setBaseUrl } from "../../../config";
import axios from "axios";
import MaintenanceTable from "../../tables/MaintenanceTable/MaintenanceTable";
import MaintenanceTab from "./MaintenanceTab";
import { useNavigate, useParams } from "react-router-dom";
import pdfIcon from "../../../assets/images/pdfIcon.png";
import { LoaderContext } from "../../../Context/LoaderContext";
import Loader from "../../Loader";
import LeaseTab from "./LeaseTab";
import NewAssetBtn from "../../Buttons/NewAssetBtn";
import { PermissionContext } from "../../../Context/PermissionsContext";
import Documents from "../../InnerTabs/Documents";
import DeviceEvents from "../Telematics/DeviceEvents";
import Timeline from "../../Timeline";
import { NoImage } from "./Tabs/NoImage";
import AssetDashboards from "./Tabs/AssetDashboards";
import CommercialDetails from "./Tabs/CommercialDetails";
import PhotoData from "./Tabs/PhotoData";
import AssetCommercial from "./Tabs/AssetCommercial";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AssetHeader from "./Tabs/AssetHeader";
import TimeLineDuration from "./TimeLineDuration.jsx";
import PhotoDataTab from "./bottomTabs/PhotoDataTab.jsx";
import RemittenceTable from "./Tabs/RemittenceTable.jsx";
import UserLogData from "./bottomTabs/UserLogData.jsx";
import { useGetAssetWorkingTimeline } from "../../../apis/AssetsApis/api.js";
import { Button, Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import Loading from "../../Loading.jsx";

function AssetDetails({ setShowCurrentTab }) {
  const [perms] = useContext(PermissionContext);
  const [loader, setLoader] = useContext(LoaderContext);
  const [assetDetails, setAssetBasicDetails] = useState({});
  const [commercialDetails, setcommercialDetails] = useState({});
  const [innerTab, setInnerTab] = useState(1);
  // Timeline Duration Reference

  const [imageUrl, setImageUrl] = useState([]);
  const [documentUrl, setDocumentUrl] = useState([]);
  const [deviceId, setDeviceId] = useState(null);
  const [commercialDetailsExist, setcommercialDetailsExist] = useState(false);
  const [epocToHumanDate, setEpocToHumanDate] = useState("");
  // const [reload, setReload] = useState(false);
  const { id, tab } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let [revenueView, setRevenueView] = useState([]);
  const [odooCost, setOdooCost] = useState(null);
  const [error, setError] = useState(null);

  let section = null;
  const [timeLine, setTimeLine] = useState({
    instance_time: {},
    working_time: [],
  });
  // console.log(sessionStorage.getItem("currentTab"))
  // let currentTab = sessionStorage.getItem("currentTab");
  const hasFetchedRevenue = useRef(false);
  const hasFetchedDashboard = useRef(false);
  // console.log(currentTab);
  const getRevenueData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${setBaseUrl}/asset/get_data_by_id`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          id: id,
          section: "revenue",
        },
      });

      const data = await response.json();
      if (data.revnew_data) {
        setRevenueView(data.revnew_data);
      }

      if (data.commercial_detail[0].odoo_cost) {
        setOdooCost(data.commercial_detail[0].odoo_cost);
      }

      if (response.status !== 200) {
        toast.error(
          "Please check Asset Commercial Details or Custom Duty Date of Clearance and Date of remmited to oem"
        );
      }
    } catch (error) {
      setLoading(false);
      // alert(error)
      toast.error(
        "Please check Asset Commercial Details or Custom Duty Date of Clearance and Date of remmited to oem"
      );
    }
  };

  useEffect(() => {
    const currentTab = sessionStorage.getItem("currentTab");

    if (currentTab === "5" && !hasFetchedRevenue.current) {
      getRevenueData();
      hasFetchedRevenue.current = true;
    }
  }, []);

  // console.log(revenueView)
  const getAssetDetails = async () => {
    // console.log(id);

    try {
      setLoader(true);
      setLoading(true);
      const response = await fetch(`${setBaseUrl}/asset/get_data_by_id`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          id: id,
          section: "asset_dashboard",
        },
      });

      const data = await response.json();
      // console.log(data);
      setDeviceId(data?.device_id);

      // if (revenueView && response.status === 200) {
      //   setLoading(false);
      // } else if(revenueView && response.status !== 200) {
      //   // setError()
      // setLoading(false)
      // toast.error("Please check Asset Commercial Details or Custom Duty Date of Clearance and Date of remmited to oem")
      // }

      setTimeLine({
        instance_time: data?.tele_data?.instance_data,
        working_time: data?.tele_data?.working_time,
      });

      setEpocToHumanDate((prev) => {
        let humanDateFormat = new Date(0);
        humanDateFormat.setUTCSeconds(data?.created_at);
        return humanDateFormat;
      });
      if (data.commercial_detail !== undefined) {
        let lenOfCommDetail = Object.keys(data.commercial_detail).length;
        // if commercial details exist in data then we set commercial details state and set commercialDetailsExist to true
        if (lenOfCommDetail > 0) {
          setcommercialDetails(data.commercial_detail);
          setcommercialDetailsExist(true);
        }
      }
      // console.log(data?.photo_data[0]?.image_uri);
      setAssetBasicDetails(data);
      setImageUrl(data?.photo_data);
      setDocumentUrl(data?.attachment_data);
    } catch (error) {
      console.error(error);
      // setError(error)
      // setLoading(false)
      // alert("There having some issue please reload!!")
    } finally {
      setLoader(false);
    }
  };

  // console.log(commercialDetails[0].total_landed_cost);

  const handleCurrentTab = (tab) => {
    sessionStorage.setItem("currentTab", tab);
    setInnerTab(tab);
  };

  // useEffect(() => {
  //   // console.log(typeof tab);
  //   if (tab == "true") {
  //     setInnerTab(1);
  //   } else {
  //     let getCurrentTabName = sessionStorage.getItem("currentTab");
  //     if (getCurrentTabName == 2) {
  //       setInnerTab(2);
  //     } else if (getCurrentTabName == 3) {
  //       setInnerTab(3);
  //     } else if (getCurrentTabName == 4) {
  //       setInnerTab(4);
  //     } else if (getCurrentTabName == 5) {
  //       setInnerTab(5);
  //     } else if (getCurrentTabName == 6) {
  //       setInnerTab(6);
  //     } else {
  //       setInnerTab(1);
  //     }
  //   }

  //   getAssetDetails();
  //   hasFetchedDashboard.current(true)
  // }, [innerTab]);
  useEffect(() => {
    const getCurrentTabName = sessionStorage.getItem("currentTab");

    // Only set `innerTab` when necessary
    if (tab === "true") {
      setInnerTab(1);
    } else {
      const tabMapping = [1, 2, 3, 4, 5, 6];
      const currentTabIndex = tabMapping.includes(Number(getCurrentTabName))
        ? Number(getCurrentTabName)
        : 1;
      setInnerTab(currentTabIndex);
    }

    if (!hasFetchedDashboard.current) {
      getAssetDetails();
      hasFetchedDashboard.current = true; // Ensures it runs only once
    }
  }, []); // Empty dependency array ensures this runs only once

  useEffect(() => {
    if (!hasFetchedDashboard.current) {
      getAssetDetails();
      hasFetchedDashboard.current = true;
    }
  }, []);

  const [showMachine, setShowMachine] = useState(false);
  const [bottomTab, setBottomTab] = useState(1); // Default tab
  const { bottom } = useParams();

  const handleBottomTab = (tab) => {
    setBottomTab(tab);
    sessionStorage.setItem("bottomTab", tab);
  };

  // useEffect to initialize the tab state based on session storage or URL params
  useEffect(() => {
    let storedTab = sessionStorage.getItem("bottomTab");

    if (bottom === "true") {
      setBottomTab(1); // Override with tab 1 if bottom is true
    } else if (storedTab) {
      setBottomTab(Number(storedTab)); // Set tab based on session storage
    }
  }, [bottom]);

  // console.log(imageUrl)

  let imageArray = [];
  if (imageUrl) {
    for (let i = 0; i < imageUrl.length; i++) {
      imageArray.push(imageUrl[i]);
    }
  }

  const [photoDataTab, setPhotoDataTab] = useState(false);

  useEffect(() => {
    if (photoDataTab) {
      // Smooth scroll to the bottom tab with an ID of 'photos'
      const photosElement = document.getElementById("photos");
      if (photosElement) {
        photosElement.scrollIntoView({ behavior: "smooth" });
      }
    }

    if (showMachine) {
      const timeElement = document.getElementById("timeline");
      if (timeElement) {
        timeElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [photoDataTab, showMachine]);

  const [logData, setLogData] = useState([]);
  const [loadingRev, setLoadingRev] = useState(false);

  const getUserLogData = async () => {
    setLoadingRev(true);
    try {
      const res = await fetch(`${setBaseUrl}/dashboard/get-log-data-user`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          "asset-id": id,
        },
      });
      const data = await res.json();
      if (res.ok) {
        setLoadingRev(false);
        setLogData(data);
      }
    } catch (error) {
      console.error("Error fetching log data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bottomTab === 3) {
      getUserLogData();
    }
  }, [bottomTab]);

  return (
    <>
      <div className=" px-2">
        <div className="">
          <AssetHeader assetDetails={assetDetails} loading={loading} />

          {/* Button Header */}
          <div className="flex justify-between gap-6 mt-2  ">
            <div className="text-[10px] items-center flex justify-start gap-3  w-full">
            <Tooltip title="Dashboard" placement="top">

              <span
                onClick={() => {
                  handleCurrentTab(1);
                }}
                className={`asset-details-tab py-2 sm:mr-8 cursor-pointer   hover:scale-105  transition-transform duration-300  dark:text-white 
                  ${innerTab === 1 ? "assetDetailsActive " : ""}`}
                  >
                Dashboard
              </span>
                </Tooltip>
              {perms.indexOf("ADMIN.ALL") > -1 ? (
                <>
                <Tooltip title="Commercial Details" placement="top">

                  <span
                    onClick={() => handleCurrentTab(2)}
                    className={`asset-details-tab py-2 sm:mr-8 cursor-pointer  hover:scale-105  transition-transform duration-300 dark:text-white
                      ${innerTab === 2 ? "assetDetailsActive " : ""}`}
                      >
                    Commercial Details
                  </span>
                    </Tooltip>
                </>
              ) : (
                ""
              )}
              <Tooltip title="Maintenance Details of the asset" placement="top">
                <span
                  onClick={() => handleCurrentTab(3)}
                  className={`asset-details-tab py-2 sm:mr-8 cursor-pointer  hover:scale-105  transition-transform duration-300 dark:text-white ${
                    innerTab === 3 ? "assetDetailsActive " : ""
                  }`}
                >
                  Maintenance
                </span>
              </Tooltip>
              <Tooltip title="Leases of the Asset" placement="top">
                <span
                  onClick={() => handleCurrentTab(4)}
                  className={`asset-details-tab py-2 sm:mr-8 cursor-pointer  hover:scale-105  transition-transform duration-300 dark:text-white ${
                    innerTab === 4 ? "assetDetailsActive " : ""
                  }`}
                >
                  Lease
                </span>
              </Tooltip>

              {perms.indexOf("ADMIN.ALL") > -1 ? (
                <>
                  {commercialDetails.length > 0 &&
                  commercialDetails !== null ? (
                    <>
                      <Tooltip title="Revenue" placement="top">
                        <span
                          onClick={() => {
                            handleCurrentTab(5);
                            // setRevenueView("revenue");
                            getRevenueData();
                          }}
                          className={`asset-details-tab py-2 sm:mr-8 cursor-pointer  hover:scale-105  transition-transform duration-300 dark:text-white ${
                            innerTab === 5 ? "assetDetailsActive " : ""
                          }`}
                        >
                          Revenue
                        </span>
                      </Tooltip>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </div>

            {/* Buttons */}
            <div className="pr-4 flex gap-4 justify-between max-sm:fixed max-sm:bottom-0 max-sm:bg-white max-sm:border max-sm:left-0 max-sm:right-0 max-sm:py-2 max-sm:z-[9999] max-sm:justify-end">
              <Tooltip title="Return to asset table" placement="top">
                <Button
                  onClick={() => navigate("/assets")}
                  variant="outlined"
                  sx={{
                    fontSize: "12px",
                    color: "#7C7C7C",
                    borderColor: "#7C7C7C",
                    textTransform: "none", // To disable uppercase text transformation
                  }}
                >
                  Back
                </Button>
              </Tooltip>
              {(perms.indexOf("ADMIN.ALL") > -1 ||
                perms.indexOf("ASSETS.ALL") > -1 ||
                perms.indexOf("ASSETS.CRU") > -1) && (
                <>
                  <Tooltip title="Edit Asset Details" placement="top">
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        textTransform: "none", // To disable uppercase text transformation
                      }}
                      onClick={() => navigate("/assets/update-asset/" + id)}
                    >
                      Edit
                    </Button>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
        </div>
        {/* details page  */}

        {loader ? (
          <Loader />
        ) : (
          <div className="mt-5">
            {/* tab 1 */}
            {innerTab === 1 && (
              <>
                <>
                  {/* Asset Details Data */}

                  {/* Photos Data */}
                  <div className="flex justify-between gap-1 flex-row max-sm:flex-col">
                    <CommercialDetails
                      assetDetails={assetDetails}
                      imageUrl={imageUrl}
                      NoImage={NoImage}
                      handleBottomTab={handleBottomTab}
                      setPhotoDataTab={setPhotoDataTab}
                    />

                    <div className="flex flex-col">
                      <DeviceEvents devId={deviceId} />

                      {deviceId && (
                        <>
                          <Link
                            to="timeline"
                            smooth
                            duration={500}
                            className=""
                          >
                            <Timeline
                              devId={deviceId}
                              setShowMachine={setShowMachine}
                              handleBottomTab={handleBottomTab}
                              timeLine={timeLine}
                            />
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                  <hr className="w-full h-1 text-dark-6" />
                  {/* Bottom Tabs */}
                  <div className="flex items-center gap-4 text-xs pl-2 mt-[15px] pb-20 text-dark-6 ">
                    <Link to="photos">
                      <button
                        className={` px-0 py-1 ${
                          bottomTab === 1 ? "border-b border-dark-6 " : ""
                        }`}
                        onClick={() => {
                          handleBottomTab(1);
                          setPhotoDataTab(true);
                        }}
                      >
                        Photos & Videos
                      </button>
                    </Link>
                    <button
                      className={` px-0 py-1 ${
                        bottomTab === 2 ? "border-b border-dark-6 " : ""
                      }`}
                      onClick={() => handleBottomTab(2)}
                    >
                      Documents
                    </button>
                    <button
                      className={` px-0 py-1 ${
                        bottomTab === 4 ? "border-b border-dark-6 " : ""
                      }`}
                      onClick={() => {
                        handleBottomTab(4);
                        setShowMachine(true);
                      }}
                    >
                      Working Time
                    </button>
                    {/* <button
                          className={`border px-4 py-1 ${
                            bottomTab === 5 ? "bg-[#8a8989] text-white" : ""
                          }`}
                          onClick={() => {
                            handleBottomTab(5);
                            setShowMachine(true);
                          }}
                        >
                         Engine Runtime
                        </button> */}
                    <button
                      className={` px-0 py-1 ${
                        bottomTab === 3 ? "border-b border-dark-6 " : ""
                      }`}
                      onClick={() => {
                        handleBottomTab(3);
                        getUserLogData();
                      }}
                    >
                      Log Data
                    </button>
                  </div>

                  {bottomTab === 1 && photoDataTab && (
                    <>
                      <div id="photos">
                        <PhotoDataTab
                          imageArray={imageArray}
                          assetName={assetDetails?.asset_no}
                        />
                      </div>
                    </>
                  )}

                  {bottomTab === 2 && (
                    <>
                      <Documents documentUrl={documentUrl} />
                    </>
                  )}

                  {bottomTab === 3 && (
                    <>
                      <UserLogData id={assetDetails.id} logData={logData} />
                    </>
                  )}

                  {bottomTab === 4 && deviceId && showMachine && (
                    <>
                      <div id="timeline">
                        <TimeLineDuration
                          devId={deviceId}
                          setShowMachine={setShowMachine}
                          timeLine={timeLine}
                        />
                      </div>
                    </>
                  )}
                </>
              </>
            )}

            {/* tab 2 */}
            {perms.indexOf("ADMIN.ALL") > -1 ? (
              <>
                {innerTab === 2 && (
                  <>
                    <div
                      id="assetD-dashboard"
                      class="assetD-tabs flex flex-col justify-between space-y-2 mt-4 mb-16 "
                    >
                      {/*Left column  */}
{/* {console.log(commercialDetails[0])} */}
                      <AssetCommercial
                        commercialDetails={commercialDetails[0]}
                        // epocToHumanDate={epocToHumanDate}
                        assetDetails={
                          assetDetails.custom_duty_date_of_clearance
                        }
                      />
                    </div>
                  </>
                )}
              </>
            ) : (
              ""
            )}

            {/* tab 3 */}
            {innerTab === 3 && (
              <div id="assetD-maintenance" className="assetD-tabs mt-4">
                <div className="text-right dark:text-white">
                  <NewAssetBtn tabName="maintenance" />
                </div>
                <MaintenanceTab asset_id={id} />
              </div>
            )}

            {/* tab 4 */}
            {innerTab === 4 && (
              <div id="assetD-maintenance" className="text-black assetD-tabs">
                <div className="text-right">
                  <NewAssetBtn tabName="lease" />
                </div>
                <LeaseTab asset_id={id} />
              </div>
            )}

            {commercialDetails !== null ? (
              <>
                {perms.indexOf("ADMIN.ALL") > -1 ? (
                  <>
                    {innerTab === 5 && (
                      <>
                        {loadingRev ? (
                          <Loading />
                        ) : (
                          <>
                            {!odooCost ? (
                              <>
                                <div className="">
                                  <div className="text-center text-red-500 text-xs items-center ">
                                    Revenue Details and Interest Depreciation
                                    Value not Available
                                  </div>
                                  <button
                                    className="text-xs p-1 text-white bg-blue-2"
                                    onClick={() => handleCurrentTab(1)}
                                  >
                                    Go to Dashboard
                                  </button>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="flex flex-col gap-6">
                                  {" "}
                                  <AssetDashboards
                                    commercialDetails={odooCost}
                                  />
                                  {revenueView && (
                                    <RemittenceTable
                                      revnew_data={revenueView}
                                      asset_sold_date={
                                        assetDetails.asset_sold_date
                                      }
                                    />
                                  )}
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default AssetDetails;
