import React, { useEffect, useState } from "react";
import { get_log_table_data } from "../../../apis/LeaseApis";
import {
  convertToHoursAndMinutes,
  currency,
  currencyInLakhs,
  formatDate,
  formatMonth,
} from "../AssetDetails/Tabs/FormatDate";
import CreateLogTable from "./CreateLogTable";
import { IoMdAdd } from "react-icons/io";
import { Button } from "@mui/material";
import EditableLogTable from "./EditableLogTable";
import LeaseWithDevice from "./LeaseWithDevice";

function LogTable({
  rso_id,
  asset_no,
  setRunDuration,
  leaseId,
  deviceId,
  nominalHourse,
  nominal_hours_per_day,
  normal_amount_rates_by_hour
}) {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewData, setViewData] = useState("view");
  const [expandedSections, setExpandedSections] = useState([]);

  const [editable, setEditable] = useState(false);
  // console.log(tableData)
  let operators = [];
  const fetchLogTableData = async () => {
    try {
      setLoading(true);
      const { data } = await get_log_table_data(rso_id);

      if(typeof data === 'string'){
        setTableData(() => JSON.parse(data));
        // JSON.parse(data);
      } else {
        setTableData(() => data);
      }
     console.log(typeof data);
    
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchLogTableData();
    
  }, []);

  let table = tableData[0] || {};
  let keys = Object.keys(table);
  console.log(tableData);
  let totalAmount = tableData[1]
    ? Object.values(tableData[1]).reduce((sum, amt) => sum + amt, 0)
    : 0;

  setRunDuration(convertToHoursAndMinutes(totalAmount));

  let calculatedOdooAmount = 0;
  let overtimeAmount = 0;
  const totalAmountMonthly = 0;
  const [monthlyAmount, setMonthlyAmount] = useState(0);

  const toggleSection = (index) => {
    setExpandedSections((prev) => {
      const newExpandedSections = [...prev];
      newExpandedSections[index] = !newExpandedSections[index];
      return newExpandedSections;
    });
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[50vh]">
        <div className="animate-pulse w-full max-w-lg p-5 text-center border rounded-xl shadow-lg bg-gray-300 text-xs">
          Loading data...
        </div>
      </div>
    );
  }

  let count = [];
  let money = 0;

  // Check if tableData is available and not empty
  if (tableData && tableData[0] && keys && keys.length > 0) {
    console.log(keys);
    // Extract the necessary values directly into the count array
    count =
      tableData[0][keys[0]]
        ?.map((item) => parseFloat(item.x_studio_normal_bill_amount))
        ?.filter((item) => !isNaN(item)) || [];

    // Calculate the total sum
    const total = count.reduce((acc, curr) => acc + curr, 0);

    // Log the total sum
    money = total;
  }

  return (
    <>
      <div className="flex items-end justify-end gap-2 mt-5 ">
        <button
          className={` text-[12px] bg-white text-dark-6 buttons ${
            viewData === true && "border border-blue-1"
          }`}
          onClick={() => setViewData("view")}
        >
          View All
        </button>

        {deviceId && (
          <button
            className=" shadow-md text-white bg-blue-2 text-[10px] buttons cursor-pointer"
            onClick={() => setViewData("see details")}
          >
            Autogenerated
          </button>
        )}

        <button
          className=" shadow-md text-white bg-blue-2 buttons cursor-pointer"
          onClick={() => setViewData("add new")}
        >
          Add Manually
        </button>
      </div>

      {viewData === "view" ? (
        <div className="mt-4">
          <div className="pt-2 px-4 mb-2 h-[50px] items-center flex gap-2 text-xs text-gray-800 w-fit bg-white shadow-md rounded-[4px]">
            Total Working Time: {convertToHoursAndMinutes(totalAmount)}
            <span>
              <strong>Monthy Amount: </strong>₹{tableData[2]?.per_month_amount}
            </span>
          </div>

          {keys.reverse().map((key, index) => {
            const { formattedMonth, year } = formatMonth(key);
            let monthData = table[key] || [];
            // console.log(tableData[1][key])
            return (
              <div
                key={index}
                className="mb-0 shadow-md text-text-1 border-b border-light-10  transition-all duration-200 ease-in "
              >
                <div
                  onClick={() => toggleSection(index)}
                  className="flex items-center justify-between px-4 py-2 shadow-xl bg-white hover:bg-[#8c8c8c] hover:text-white hover:shadow-md h-[32px] cursor-pointer"
                >
                  <div className="text-[10px] font-semibold">
                    {formattedMonth}, {year}
                  </div>
                  <div className="flex text-[10px] ">
                    <span className="gap-2">
                      <strong>Monthly Amount:{"  "}</strong>₹
                      {parseFloat(money).toFixed(2)}
                    </span>
                  </div>
                </div>

                {expandedSections[index] && (
                  <div className="overflow-x-auto text-[10px] transition-all duration-300 ease-in p-4 border  bg-light-11">
                    {!editable && (
                      <Button
                        sx={{ fontSize: 10 }}
                        variant="contained"
                        onClick={() => setEditable(true)}
                      >
                        Edit
                      </Button>
                    )}

                    {editable ? (
                      <>
                        <EditableLogTable
                          monthData={monthData}
                          setEditable={setEditable}
                          asset_no={asset_no}
                        />
                      </>
                    ) : (
                      <>
                        {/* Only Display data  */}
                        <table className="w-full table-auto text-center bg-white">
                          <thead className="border-b">
                            <tr>
                              <th className="p-2 ">Date</th>
                              <th className="p-2 ">Day Type</th>
                              <th className="p-2 ">Time In</th>
                              <th className="p-2 ">Time Out</th>
                              <th className="p-2 ">Total Time</th>
                              <th className="p-2 ">Overtime</th>
                              <th className="p-2 ">Time Adjust</th>
                              <th className="p-2 ">Bill Amount</th>
                              <th className="p-2 ">Overtime Amount</th>
                              <th className="p-2 ">Operator </th>
                            </tr>
                          </thead>
                          <tbody>
                            {monthData.map((item, i) => {
                              calculatedOdooAmount +=
                                item.x_studio_normal_bill_amount;
                              overtimeAmount += item.x_studio_overtime_amount;

                              if (item.x_studio_operator) {
                                operators.push(item.x_studio_operator);
                              }
                              return (
                                <tr key={i} className="border-b">
                                  <td className="p-2 ">
                                    {formatDate(item.x_studio_date)}
                                  </td>
                                  <td className="p-2 ">
                                    {item.x_studio_day_type}
                                  </td>
                                  <td className="p-2 ">
                                    {parseFloat(item.x_studio_time_in).toFixed(
                                      2
                                    )}
                                  </td>
                                  <td className="p-2 ">
                                    {parseFloat(item.x_studio_time_out).toFixed(
                                      2
                                    )}
                                  </td>
                                  <td className="p-2 ">
                                    {parseFloat(
                                      item.x_studio_total_time
                                    ).toFixed(2) || "N/A"}
                                  </td>
                                  <td className="p-2 ">
                                    {parseFloat(item.x_studio_overtime).toFixed(
                                      2
                                    )}
                                  </td>
                                  <td className="p-2 ">
                                    {item.x_studio_time_adjust || "N/A"}
                                  </td>
                                  <td className="p-2 ">
                                    <div className="flex items-center justify-center">
                                      {parseFloat(
                                        item.x_studio_normal_bill_amount
                                      ).toFixed(2) || "N/A"}
                                      <span className="pl-1">₹</span>
                                    </div>
                                  </td>
                                  <td className="p-2 ">
                                    <div className="flex items-center justify-center">
                                      {parseFloat(
                                        item.x_studio_overtime_amount
                                      ).toFixed(2) || "N/A"}
                                      <span className="pl-1">₹</span>
                                    </div>
                                  </td>
                                  <td className="p-2 ">
                                    {item.x_studio_operator}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ) : viewData === "see details" ? (
        <>
          {deviceId && (
            <LeaseWithDevice
              rso_id={rso_id}
              asset_no={asset_no}
              leaseId={leaseId}
              deviceId={deviceId}
              nominalHourse={nominalHourse}
              operators={operators}
              nominal_hours_per_day={nominal_hours_per_day}
              normal_amount_rates_by_hour={normal_amount_rates_by_hour}
            />
          )}
        </>
      ) : (
        <CreateLogTable
          rso_id={rso_id}
          asset_no={asset_no}
          leaseId={leaseId}
          tableData={tableData[0]}
          setViewData={setViewData}
        />
      )}
    </>
  );
}

export default LogTable;
