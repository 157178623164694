import { Tooltip } from '@mui/material'
import React from 'react'

const LeaseTableHeader = (
    {
       activeCount,expiredCount, 
        leaseDetails
    }
) => {

    const HeaderData = [{
        title: "Total Items",
        label: "Total number of lease generated",
        data: leaseDetails.length
    },{
        title: "Active Lease",
        label: "Total number of active lease",
        data: activeCount,
    },{
        title: "Expired Lease",
        label: "Total number of expired lease",
        data: expiredCount
    }]
  return (
    <>

    <div className="flex mb-3 gap-3 text-xs mt-2 justify-start">
        {HeaderData.map((data) => <>
    <Tooltip title={data.label} arrow placement="bottom-end">
        <div className="bg-white  p-3 w-[160px]  rounded-[6px] shadow-md" key={data.id}>
            <h1 className="">{data.title}</h1>
            <span className="text-base font-extrabold text-light-2">{data.data}</span>
        </div>
        </Tooltip>
        </>)}
    </div>
    </>
  )
}

export default LeaseTableHeader
