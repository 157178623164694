import React, { useEffect, useState } from "react";
import "./button.css";

import io from "socket.io-client";
import { lastEventData } from "../../../apis/Telematics/last_event_data";
import LocationMarker from "./LocationMarker";
import DeviceMap from "./DeviceMap";
import { Tooltip } from "@mui/material";
function DeviceEvents({ devId }) {
  const [internalBattery, setInternalBattery] = useState(null);
  const [vehicleBatteryVol, setVehicleBatteryVol] = useState(null);
  const [mapPosition, setMapPosition] = useState([22.539999, 88.354111]);
  const [gpsGroundSpeed, setGpsGroundSpeed] = useState(0);
  const [gpsAltitude, setGpsAltitude] = useState("OFF");
  const [turnSwitch, setTurnSwitch] = useState(false);
  const [DeviceOnOff, setDeviceOnOff] = useState("OFF");

  useEffect(() => {
    // console.log(devId);
    const socket = io("https://websocket.durbinservices.com/", {
      extraHeaders: {
        "x-access-token": process.env.REACT_APP_SOCKET_DATA_TOKEN,
      },
    });

    socket.on(`genie/maco-storm/remote-start-stop/${devId}`, (data) => {
    
    });
    return () => {
      socket.disconnect();
    };
  }, [
    mapPosition,
    vehicleBatteryVol,
    // internalBattery,
    DeviceOnOff,
  ]);

  const deviceOnOffEvent = (triggeredEvent) => {
    const socket = io("https://websocket.durbinservices.com/");
    if (turnSwitch == false) {
      socket.emit(
        "client-message",
        `genie/maco-storm/remote-start-stop/${devId}#TURN_OFF`
      );
    } else {
      socket.emit(
        "client-message",
        `genie/maco-storm/remote-start-stop/${devId}#TURN_ON`
      );
    }
    setTurnSwitch((prev) => !prev);
  };

  const isDataRecent = (timestamp) => {
    const fifteenMinutesInMs = 15 * 60 * 1000;
    const now = new Date().getTime();
    return now - timestamp <= fifteenMinutesInMs;
  };


  const isWithinLast15Minutes = (timestamp) => {
    const currentTime = new Date();
    const dataTime = new Date(timestamp);
    return (currentTime - dataTime) / (1000 * 60) <= 15;
  };
  const getAllEventData = async () => {
    try {
      let getData = await lastEventData(devId);
  
      // // Device Internal Battery
      // if (getData?.pwr_data_push) {
      //   const rawData = JSON.parse(getData?.pwr_data_push?.data);
      //   const SOC = rawData["SOC"]; // Limit SOC to 2 decimals
      //   const currentTime = new Date().getTime();
      //   const storedSOC = JSON.parse(localStorage.getItem("SOCData"));
  
      //   // Check if SOC is within valid range or within 15-minute range
      //   if (SOC <= 1 || SOC > 100) {
      //     if (storedSOC && isDataRecent(storedSOC.timestamp)) {
      //       setInternalBattery(parseInt(storedSOC.value));
      //     } else { 
      //       setInternalBattery(SOC > 100 ? 100 : "1");
      //       localStorage.setItem(
      //         "SOCData",
      //         JSON.stringify({ value: SOC, timestamp: currentTime })
      //       );
      //     }
      //   } else {
      //     setInternalBattery(Math.floor(SOC));
      //     localStorage.setItem(
      //       "SOCData",
      //       JSON.stringify({ value: SOC, timestamp: currentTime })
      //     );
      //   }
      // }
      if (getData?.pwr_data_push) {
        const rawData = JSON.parse(getData?.pwr_data_push?.data);
        let SOC = parseFloat(rawData["SOC"]); // Parse SOC as a number
        const currentTime = new Date().getTime();
        const storedSOC = JSON.parse(localStorage.getItem("SOCData"));
      
        // Ensure SOC is within the valid range: [1, 100]
        if (SOC <= 1) {
          SOC = 1; // Minimum value is 1
        } else if (SOC >= 100) {
          SOC = 100; // Maximum value is 100
        }
      
        // Check if stored SOC is recent
        if (storedSOC && isDataRecent(storedSOC.timestamp)) {
          setInternalBattery(parseInt(storedSOC.value));
        } else {
          setInternalBattery(Math.floor(SOC));
          localStorage.setItem(
            "SOCData",
            JSON.stringify({ value: SOC, timestamp: currentTime })
          );
        }
      }
      
  
      // Compare Battery Voltage
      const pwrDataPushTime = getData?.pwr_data_push?.created_at
      ? new Date(getData?.pwr_data_push?.created_at)
      : null;
    
    const vehicleStatusTime = getData?.vehicle_engine_status?.created_at
      ? new Date(getData?.vehicle_engine_status?.created_at)
      : null;
    
    const now = Date.now();  // Current time
    
    // Check if both times exist and compare them
    let vehicleBatteryVol = null;
    
    if (pwrDataPushTime && vehicleStatusTime) {
      const pwrDiff = Math.abs(now - pwrDataPushTime.getTime()); // Difference in milliseconds
      const vehicleStatusDiff = Math.abs(now - vehicleStatusTime.getTime()); // Difference in milliseconds
    
      if (pwrDiff > vehicleStatusDiff) {
        // pwr_data_push is closer to the current time
        const exVolt = Number(JSON.parse(getData?.pwr_data_push?.data)?.ExVOLT).toFixed(2);
          setVehicleBatteryVol(exVolt);
          vehicleBatteryVol = exVolt;
        
     
      } else {
        // vehicle_engine_status is closer to the current time
        const voltage = getData?.vehicle_engine_status?.data.split(" - ")[1]?.replace("v", "").trim();
        setVehicleBatteryVol(voltage);
        vehicleBatteryVol = voltage;
      }
    } else if (pwrDataPushTime) {
      // Only pwr_data_push exists
      const exVolt = JSON.parse(getData?.pwr_data_push?.data)?.ExVOLT;
      setVehicleBatteryVol(exVolt);
      vehicleBatteryVol = exVolt;
    } else if (vehicleStatusTime) {
      // Only vehicle_engine_status exists
      const voltage = getData?.vehicle_engine_status?.data.split(" - ")[1];
      setVehicleBatteryVol(voltage);
      vehicleBatteryVol = voltage;
    } 
    
    // Set device ON/OFF status based on vehicleBatteryVol
    if (vehicleBatteryVol >= 13.45) {
      setDeviceOnOff("ON");
    } else {
      setDeviceOnOff("OFF");
    }
    
  
      // GPS Data
      if (getData?.gps_push) {
        try {
          const rawData = JSON.parse(getData?.gps_push?.data);
      
          // Extract GPS data and ensure they are numbers
          const lat = (Number(rawData.lat) / 1000000).toFixed(6); // Limit GPS to 6 decimals
          const lng = (Number(rawData.lng) / 1000000).toFixed(6);
          const speed = (Number(rawData?.speed) || 0).toFixed(2); // Handle missing speed
          const altitude = (Number(rawData?.altitude) || 0).toFixed(2); // Handle missing altitude
      
          // Update map position and other data
          setMapPosition([parseFloat(lat), parseFloat(lng)]);  // Convert to float after toFixed
          setGpsGroundSpeed(speed);
          setGpsAltitude(altitude);
        } catch (error) {
          console.error("Error parsing GPS data:", error);
        }
      }
      
    } catch (error) {
      console.error("Error fetching event data:", error);
    }
  };
  
  
  
  useEffect(() => {
    getAllEventData();
  }, []);
  
  
//   const getAllEventData = async () => {

//     try {
//       let getData = await lastEventData(devId);

//       // Device Internal Battery
//       if (getData?.pwr_data_push) {
//         const rawData = JSON.parse(getData?.pwr_data_push?.data);
// const SOC = localStorage.setItem('SOC', rawData["SOC"])
//         try {
//           let batteryData = SOC;
//           setInternalBattery(
//             batteryData > 100
//               ? 100
//               : batteryData <= 1
//               ? "1"
//               : Math.floor(batteryData)
//           );
//           // console.log(batteryData)
//         } catch (error) {
//           console.error("Error parsing internal battery data:", error);
//         }
//       }

//       if (getData?.vehicle_engine_status.data.split(" - ")[0] === "EngineON") {
//         setDeviceOnOff("ON");
//       } else {
//         setDeviceOnOff("OFF");
//       }

//       // Vehicle Battery Voltage
//       if (getData?.vehicle_engine_status) {
//         try {
//           let splitVoltage =
//             getData?.vehicle_engine_status.data.split(" - ")[1];

//           setVehicleBatteryVol(splitVoltage);
//         } catch (err) {
//           console.error("Error parsing vehicle battery voltage:", err);
//         }
//       }

//       // GPS Data
//       if (getData?.gps_push) {
//         let rawData;
//         try {
//           rawData = getData?.gps_push?.data;
//           const parsedData = JSON.parse(rawData);

//           // Access lat and lng
//           const lat = parsedData.lat / 1000000;
//           const lng = parsedData.lng / 1000000;

//           setMapPosition([lat, lng]);
//           setGpsGroundSpeed(parsedData?.speed);
//           setGpsAltitude(parsedData?.altitude);
//           // console.log("Map Position", mapPosition);
//         } catch (error) {
//           console.error("Error parsing GPS data:", error);
//         }
//       }
//     } catch (error) {
//       console.error("Error fetching event data:", error);
//     }
//   };

//   useEffect(() => {
//     // console.log(devId);
//     getAllEventData();
//   }, []);


// const getAllEventData = async () => {
//   try {
//     let getData = await lastEventData(devId);

//     // Device Internal Battery
//     if (getData?.pwr_data_push) {
//       const rawData = JSON.parse(getData?.pwr_data_push?.data);
//       const SOC = rawData["SOC"];
//       const currentTime = new Date().toISOString();

//       // Retrieve previous data from localStorage
//       const previousSOCData = JSON.parse(localStorage.getItem("SOCData"));

//       if (SOC <= 1 || SOC > 100) {
//         if (
//           previousSOCData &&
//           isWithinLast15Minutes(previousSOCData.timestamp)
//         ) {
//           // Use previous valid SOC if it's within the last 15 minutes
//           setInternalBattery(Math.floor(previousSOCData.value));
//         } else {
//           // No valid recent data, set current (unmodified) SOC
//           setInternalBattery(Math.floor(SOC));
//           localStorage.setItem(
//             "SOCData",
//             JSON.stringify({ value: SOC, timestamp: currentTime })
//           );
//         }
//       } else {
//         // SOC is within normal range; store and display it
//         setInternalBattery(Math.floor(SOC));
//         localStorage.setItem(
//           "SOCData",
//           JSON.stringify({ value: SOC, timestamp: currentTime })
//         );
//       }
//     }

   
//     // if (getData?.vehicle_engine_status.data.split(" - ")[0] === "EngineON") {
//     //   setDeviceOnOff("ON");
//     // } else {
//     //   setDeviceOnOff("OFF");
//     // }

//     // Vehicle Battery Voltage
//     if (getData?.vehicle_engine_status) {
//       try {
//         let splitVoltage = getData?.vehicle_engine_status.data.split(" - ")[1];
//         setVehicleBatteryVol(splitVoltage);
//       } catch (err) {
//         console.error("Error parsing vehicle battery voltage:", err);
//       }
//     }

//     // GPS Data
//     if (getData?.gps_push) {
//       let rawData;
//       try {
//         rawData = getData?.gps_push?.data;
//         const parsedData = JSON.parse(rawData);

//         // Access lat and lng
//         const lat = parsedData.lat / 1000000;
//         const lng = parsedData.lng / 1000000;

//         setMapPosition([lat, lng]);
//         setGpsGroundSpeed(parsedData?.speed);
//         setGpsAltitude(parsedData?.altitude);
//       } catch (error) {
//         console.error("Error parsing GPS data:", error);
//       }
//     }
//   } catch (error) {
//     console.error("Error fetching event data:", error);
//   }
// };

// useEffect(() => {
//   getAllEventData();
// }, []);



  return (
    // d8ee6ad2-6639-4783-bd28-a5db20caafff
    // d8ee6ad2-6639-4783-bd28-a5db20caafff

    <>
      <div className=" flex max-md:flex-col  w-full  h-fit pb-4  justify-between gap-1   items-center mx-auto ">
        <div className="flex flex-col bg-white shadow-md rounded-[6px]   gap-2 py-6 max-md:w-full w-[200px] px-2 border text-left">
          <h1 className="text-sm font-extrabold text-dark-6">
            Telematics Data
          </h1>
          <>
          <Tooltip title="Asset Internal Battery Voltage" arrow placement="top-end">
            <div className="  items-start bg text-dark-6 max-md:w-full w-[180px] text-xs py-0.5  my-1 flex flex-col justify-start  transition-all ease-in-out duration-300">
              <span className="text-xs flex ">
                Asset Tracker internal battery
              </span>
              <span className="text-xs h-8 max-md:w-full w-[180px] px-2 items-center flex justify-start bg-light-4 text-dark-6 font-bold">
                {internalBattery == null
                  ? "N/A"
                  : internalBattery <= 1
                  ? "1%"
                  : internalBattery + "%"}
              </span>
            </div>
                  </Tooltip>
          </>
          <>
          <Tooltip title="External Battery Voltage" arrow placement="top-end">
            <div className=" items-start bg text-dark-6 max-md:w-full w-[200px] text-xs py-0.5  my-1 flex flex-col justify-start  transition-all ease-in-out duration-300">
              <span className="flex text-xs">Vehicle Battery Voltage</span>
              <span className="text-xs h-8 max-md:w-full w-[180px] px-2 items-center flex justify-start bg-light-4 text-dark-6 font-bold">
                {vehicleBatteryVol == null ? "N/A" : vehicleBatteryVol + "V"}
              </span>
            </div>
          </Tooltip>
          </>

          <>
          <Tooltip title="External Engine ON/OFF Status" arrow placement="top-end">
            <div className=" items-start bg text-dark-6 max-md:w-full w-[180px] text-xs py-0.5  my-1 flex flex-col justify-start  transition-all ease-in-out duration-300">
              <span className="flex">Engine ON-OFF status</span>
              <span className="text-xs h-8 max-md:w-full w-[180px] px-2 items-center flex justify-start bg-light-4 text-dark-6 font-bold">
                {" "}
                {DeviceOnOff}
              </span>
            </div>
          </Tooltip>
          </>
        </div>

        <div className=" items-start flex justify-start rounded-[6px] shadow-md border w-fit p-3 bg-white">
          <DeviceMap
            mapPosition={mapPosition}
            setMapPosition={setMapPosition}
          />
        </div>
      </div>
    </>
  );
}

export default DeviceEvents;

// TOPIC
// client-message

// MESSAGE DATA
// genie/maco-storm/remote-start-stop/ca3b612b-dc7e-4508-933d-a41aceab6e7b#TURN_ON
// genie/maco-storm/remote-start-stop/ca3b612b-dc7e-4508-933d-a41aceab6e7b#TURN_OFF
